import {  Circle,   Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

const CategoryCard = ({ img ,title , idCat}) => {

  return (
    <Link to={`/allproducts?idCategory=${idCat}`}>
    <VStack  alignItems='center' _hover={{transform:'scale(1.1)'}} transition='ease-out all .5s'>
      
        <Circle 
        boxShadow={'0px 0px 7px rgba(0,0,0,0.4)'}
        w={{base:'120px'}}
        h={{base:'120px'}}
        backgroundColor={'transparent'} 
        p='15px' display={'flex'} 
        mb='5px'
        >
        <Image src={img} w={'80px'} h='80px'/>
        </Circle>
        <Text textAlign={"center"} fontSize={'20px'} fontWeight={'semibold'}>{title}</Text>
    </VStack>
    </Link>
  )
}

export default CategoryCard