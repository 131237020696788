import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  Text,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";

import { FaSun, FaMoon } from "react-icons/fa";
import { FiLogIn } from "react-icons/fi";
import { BsCart4 } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import LogoBlack from "../../images/FinoBlack.png";
import LogoWhite from "../../images/FinoWhite.png";
import { Link } from "react-router-dom";
import { ChevronDownIcon, HamburgerIcon } from "@chakra-ui/icons";
import NavBurger from "./NavBurger";
import NavbarSearchHook from "./../../hook/search/navbar-search-hook";
import GetAllUserCartHook from "./../../hook/cart/get-all-user-cart-hook";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const NavBarLogin = () => {
  const { t, i18n } = useTranslation();

  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";

  const [OnChangeSearch, searchWord] = NavbarSearchHook();
  let word = "";
  if (localStorage.getItem("searchWord") != null)
    word = localStorage.getItem("searchWord");

  const [user, setUser] = useState("");
  useEffect(() => {
    if (localStorage.getItem("user") != null) {
      setUser(JSON.parse(localStorage.getItem("user")));
    }
    if (localStorage.getItem("fakeUser") === null) {
      localStorage.setItem("fakeUser", uuidv4());
    }
  }, []);

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    setUser("");
    window.location.href = "/";
  };
  const [itemsNum] = GetAllUserCartHook();

  return (
    <HStack w="100%" py="3" mb="30px">
      <Container
        maxW={{
          xl: "container.xl",
          lg: "container.lg",
          md: "container.md",
          sm: "container.sm",
          base: "400px",
        }}
      >
        <Flex gap={"25px"} alignItems={"center"}>
          <Link to="/">
            {" "}
            <Image
              w={{ md: "170px", base: "350px" }}
              h={{ md: "50px", base: "40px" }}
              src={isDark ? LogoWhite : LogoBlack}
            ></Image>
          </Link>
          <Input
            value={word}
            onChange={OnChangeSearch}
            type="search"
            placeholder={`${t("search")} ....`}
            w="100%"
            variant={"unstyled"}
            border={"3px solid rgba(0,0,0,0.1)"}
            p="4.5px 16px"
            fontSize={"17px"}
            fontWeight={"semibold"}
            opacity=".8"
          ></Input>
          <Box display={{ base: "none", sm: "block" }}>
            {user != "" ? (
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                  {user.name}
                </MenuButton>
                <MenuList>
                  {user.role === "admin" ? (
                    <MenuItem>
                      <Link to="/admin/allproducts" className="link">
                        {t("dashboard")}
                      </Link>
                    </MenuItem>
                  ) : (
                    <MenuItem>
                      <Link to="/user/profile" className="link">
                        {t("Profile")}
                      </Link>
                    </MenuItem>
                  )}

                  <MenuItem onClick={logOut}>{t("logout")}</MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <Link to="/login" className="link">
                <Button w="120px" variant={"unstyled"}>
                  <Flex alignItems={"center"} gap="5px">
                    <Text>{t("login")}</Text>
                    <FiLogIn />
                  </Flex>
                </Button>
              </Link>
            )}
          </Box>

          <Box display={{ base: "none", sm: "block" }}>
            <Link to="/cart" className="link">
              <Button>
                <Flex alignItems={"center"} gap="5px">
                  <Text>{t("cart")}</Text>
                  <BsCart4 />
                </Flex>
              </Button>
            </Link>
          </Box>
          <IconButton
            isRound={true}
            icon={isDark ? <FaSun /> : <FaMoon />}
            onClick={toggleColorMode}
          ></IconButton>
          <Box display={{ base: "block", md: "none" }}>
            <NavBurger logOut={logOut} user={user} />
          </Box>
        </Flex>
      </Container>
    </HStack>
  );
};

export default NavBarLogin;
