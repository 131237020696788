import {  SimpleGrid, Spinner, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import CategoryCard from '../category/CategoryCard'
import SubTittle from '../Utils/SubTittle'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCategory } from '../../redux/actions/cateogryAction'
import { useTranslation } from 'react-i18next'

const HomeCategory = () => {
  const { t, i18n } = useTranslation();
  
  const dispatch = useDispatch()
  const res = useSelector(state => state.allCategory.category)
  const loading = useSelector(state => state.allCategory.loading)


  useEffect(() => {
   dispatch(getAllCategory())
  },[dispatch ,loading])
  

  return (
    <VStack w='100%' mb='60px'>
    <SubTittle title={t('category')} btntitle={t('more')} pathText={'/allcategory'}/>
    {loading? 
    <Spinner size='lg' /> :(
      <SimpleGrid columns={{xl:[6] ,lg:[5] , md:[4] , sm:[3] , base:[2] }} w='100%' spacing={'60px'} >
          {res?.data && res?.data.length > 0 ?
              (res?.data?.slice(0,6).map(category=>(
                <CategoryCard key={category._id} idCat={category._id} img={category.image} title={category.name}/>
              ))):(
                <div>no category</div>
              )
          }
    </SimpleGrid>
    )}

    </VStack>
  )
}

export default HomeCategory