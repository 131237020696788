import { Button, FormControl, FormLabel,  Input, InputGroup, InputLeftElement, InputRightElement, Spinner, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import {ViewIcon, ViewOffIcon} from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
import LoginHook from '../../hook/auth/login-hook'
import { useTranslation } from 'react-i18next'


const Login = () => {
  const { t, i18n } = useTranslation();

    const [show, setShow] = useState(false)
    const [email, password, loading, onChangeEmail, onChangePassword, onSubmit, isPress] = LoginHook();

  return (
    <form style={{width:"100%"}}>
    <VStack>
        <FormControl isRequired>
        <FormLabel color={'yellow.500'}>{t("email")}</FormLabel>
        <Input 
        value={email}
        onChange={onChangeEmail}
        type='email'
        placeholder={t("enterEmail")}
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        />
        </FormControl>
        <FormControl isRequired>
        <FormLabel color={'yellow.500'}>{t("password")}</FormLabel>
        <InputGroup>
        <Input 
        value={password}
        onChange={onChangePassword}
        type={show?'text' : "password"} 
        placeholder={t("enterPassword")}
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        mb="20px"
        />
        {
          i18n.language ==="ar" && (<InputLeftElement>
            <Button onClick={()=>{setShow(!show)}}>{show?<ViewOffIcon/>: <ViewIcon/>}</Button>
    
            </InputLeftElement>)
        }

{
          i18n.language ==="en" && (<InputRightElement>
            <Button onClick={()=>{setShow(!show)}}>{show?<ViewOffIcon/>: <ViewIcon/>}</Button>
        </InputRightElement>)
        }
        
            
        </InputGroup>
        </FormControl>
        <Button w={'100%'} type='submit' colorScheme={'yellow'} onClick={onSubmit}>{t("login")}</Button>
        {isPress === true ? (loading === true ? (<Spinner size='lg' /> ) : null) : null}
      
    </VStack>
    </form>
  )
}

export default Login