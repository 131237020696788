import {  Box,  IconButton,  Text, useDisclosure, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import {SettingsIcon} from '@chakra-ui/icons'
import AdminSideBarSmallScreen from './AdminSideBarSmallScreen'

const AdminSideBar = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <Box>
        <Box w='100%' mb='20px'>
    <IconButton icon={<SettingsIcon/>} onClick={onOpen} display={{base:'block' , md:'none'}}/>
    </Box>
    <AdminSideBarSmallScreen isOpen={isOpen} onClose={onClose}/>
    <VStack display={{base:'none' , md:'block'}} h='600px' p='20px'   borderRadius='5px' boxShadow={'0 0 7px rgba(0,0,0,0.4)'}>
        <Link to='/admin/allorders' className='link' style={{width:'100%'}} >
            <Text  borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Order Management</Text>
        </Link>
        <Link to='/admin/allproducts' className='link' style={{width:'100%'}} >
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Products Management</Text>
        </Link>
        <Link to='/admin/addbrand' className='link' style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4'  fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Add Brand</Text>
        </Link>
        <Link to='/admin/addcategory' className='link'  style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Add Category</Text>
        </Link>
       
        <Link to='/admin/addproduct' className='link'  style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Add Product</Text>
        </Link>
        <Link to='/admin/addslider' className='link'  style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Add Slider</Text>
        </Link>
        <Link to='/admin/addpromo' className='link'  style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Add Promo</Text>
        </Link>
        <Link to='/admin/coupon' className='link'  style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Add Coupon</Text>
        </Link>
    </VStack>
    </Box>
  )
}

export default AdminSideBar