import { Box, Heading, Text, VStack } from '@chakra-ui/react'
import React from 'react'

const ShippingPolicy = () => {
  return (
    <Box display={"flex"} justifyContent={"center"}>
       <Box   w={{md:"50%" , base :"100%"}} >
            <Heading textAlign={"center"} mb={"4rem"}>Shipping policy</Heading>
            <Box display={"flex"} flexDir={"column"} gap={"20px"} opacity={".8"}>

            <Text>
            Once you have placed your order, we will send you a confirmation email/text message to your number to track the status of your order.
            </Text>
            <Text>
            Once your order is shipped we will send you another email/text message to confirm you the expected delivery date as well as the link to track your order. 


            </Text>
            <Heading fontSize={"xl"}>Shipping Times</Heading>
            <Text>
            Once the order is placed, it needs:
            </Text>
            <Text>
            <b>1-3</b> working days to arrive to your doorstep inside Lebanon.
            </Text>
            <Text>
            The delivery company will contact you on the number provided prior arriving to the shipping address for more address info and to ensure that the receiver is available at the mentioned address.
            </Text>
            <Text>
            For any further assistance, don’t hesitate to e-mail us at <b>Finova.online@gmail.com</b>  and we’ll be more than happy to assist you.
            </Text>
            <Text>
            Happy shopping and keep spreading love!
            </Text>
            <Heading fontSize={"xl"}>- Fino Lebanon</Heading>
            </Box>
       </Box>
        
    </Box>
  )
}

export default ShippingPolicy