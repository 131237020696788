import { SimpleGrid, Spinner, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import SubTittle from '../Utils/SubTittle'
import BrandCard from './BrandCard'
import { useDispatch, useSelector } from 'react-redux'
import { getAllBrand } from '../../redux/actions/brandAction'

const Brand = ({title,btntitle}) => {
  const res = useSelector(state => state.allBrand.brand)
  const loading = useSelector(state => state.allBrand.loading)

  
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllBrand())
  }, [dispatch])
  
  return (
    <VStack mb='60px'>
    <SubTittle title={title} btntitle={btntitle} pathText={'/allbrands'}/>
    { loading ? 
        <Spinner size='lg' /> : 
        (
          <SimpleGrid columns={{xl:[4] , lg:[4] , md :[3] , sm:[2] , base:[2]}} spacing={'36px'} w='100%'>
              {
                res?.data && res?.data.length > 0 ?
                  (
                    res.data?.slice(0,8).map((item) => (<BrandCard  img={item.image} key={item._id}/>))
                  ):(
                    <p>No Data Found</p>
                  )
              }
          
        
          </SimpleGrid>
        )
      }
    
    </VStack>
  )
}

export default Brand