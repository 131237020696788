import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  updateReviewOnProduct } from './../../redux/actions/reviewAction';
import { useToast } from '@chakra-ui/react';
const EditRateHook = (review) => {
    const toast = useToast()

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true)

    const [newRateText, setNewRateText] = useState(review.review);
    const [newRateValue, setNewRateValue] = useState(review.rating);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    const onChangeRateText = (e) => {
        setNewRateText(e.target.value)
    }
    const OnChangeRateValue = (val) => {
        setNewRateValue(val)
    }

    const handelEdit = async () => {
        setLoading(true)
        await dispatch(updateReviewOnProduct(review._id, {
            review: newRateText,
            rating: newRateValue
        }))
        setLoading(false)
        handleCloseEdit();
    }
    const res = useSelector(state => state.reviewReducer.updateReview)

    useEffect(() => {
        if (loading === false) {
           
            if (res.status && res.status === 200) {
                toast({
                    title:'review  edited  successfully',
                    status:'success',
                    duration:3000,
                    isClosable:true 
                    })
                setTimeout(() => {
                    window.location.reload(false)
                }, 1000);
            }
            else
            toast({
                title:'there is problem to editing your review ',
                status:'success',
                duration:3000,
                isClosable:true 
                })
        }
    }, [loading])

    return [showEdit, handleCloseEdit, handleShowEdit, handelEdit, onChangeRateText, newRateText, OnChangeRateValue, newRateValue]

}


export default EditRateHook