import { base } from "../../api/baseTextUrl";
import useDeleteData from "../../hooks/useDeleteData";
import { useGetData } from "../../hooks/useGetData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import { useInUpdateDataWithImage } from "../../hooks/useUpdateData";
import { ADD_CATEGORY, DELETE_CATEGORY, GET_ALL_CATEGORY, GET_ERROR, GET_ONE_CATEGORY, GET_ONE_CATEGORY_AR, GET_ONE_CATEGORY_EN, UPDATE_CATEGORY } from "../types";

import axios from "axios"

export const getAllCategory = (limit ,page)=> async(dispatch)=>{
    try {
        const res =await useGetData(`api/v1/categories?limit=${limit}&page=${page}`)
        dispatch({
            type:GET_ALL_CATEGORY,
            payload:res?.data
        })
    } catch (error) {
        dispatch({
            type:GET_ERROR,
            payload:"Error : " + error?.response?.data
        })
    }
}

//get one category with
export const getOneCategory = (id) => async (dispatch) => {
    try {
        const response = await useGetData(`/api/v1/categories/${id}`);

        dispatch({
            type: GET_ONE_CATEGORY,
            payload: response?.data,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
}

export const deleteCategory = (id) => async (dispatch) => {
    try {
        const response = await useDeleteData(`/api/v1/categories/${id}`);

        dispatch({
            type: DELETE_CATEGORY,
            payload: response,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
}

export const addCategory = (data) => async(dispatch)=>{
    try{
        const res =await useInsertDataWithImage('api/v1/categories' , data)
        dispatch({
            type: ADD_CATEGORY,
            payload:res,
        })
    }catch(error){
        dispatch({
            type: GET_ERROR,
            payload:"Error : " + error?.response?.data
        })
    }
    
}

export const updateCategory = (id ,data) => async(dispatch)=>{
    try {
        const response = await useInUpdateDataWithImage(`/api/v1/categories/${id}` , data);

        dispatch({
            type: UPDATE_CATEGORY,
            payload: response,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
    
}


export const getOneCategoryAr = (id) => async(dispatch)=>{
    try {
        const response = await axios.get(`${base}/api/v1/categories/${id}?lang=ar`);

        dispatch({
            type: GET_ONE_CATEGORY_AR,
            payload: response,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
    
}

export const getOneCategoryEn = (id) => async(dispatch)=>{
    try {
        const response = await axios.get(`${base}/api/v1/categories/${id}?lang=en`);

        dispatch({
            type: GET_ONE_CATEGORY_EN,
            payload: response,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
    
}