import { Box, Image } from "@chakra-ui/react";
import React from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import Logo from "../../images/eco-bag-vector-icon-removebg-preview.png";
import { useParams } from "react-router-dom";
import ViewProductsDetalisHook from "../../hook/products/view-products-detalis-hook";

const ProductGalleryDesign = () => {
  const { id } = useParams();
  const [item, cat, brand, prod, imageCover] = ViewProductsDetalisHook(id);

  // const images = [
  //     {
  //       original: `${Logo}`,
  //     },
  //     {
  //       original: `${Logo}`,
  //     },
  //     {
  //       original: `${Logo}`,
  //     },
  //   ];

  return (
    <Box
      w="100%"
      h="480px"
      borderRadius={"10px"}
      boxShadow={"0 0 7px rgba(0,0,0,0.5)"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {/* <ImageGallery
      
        items={images} 
        defaultImage={Logo}
        showFullscreenButton={false}
        showPlayButton={false}
        
        /> */}
      <Image src={imageCover} h="90%" />
    </Box>
  );
};

export default ProductGalleryDesign;
