import { EditIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { GiRoundStar } from 'react-icons/gi'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { deleteProducts } from '../../redux/actions/productsAction'

const AllProductsCard = ({item}) => {

  const dispatch = useDispatch();

    const handelDelete = async () => {

        await dispatch(deleteProducts(item._id))
 
        window.location.reload();
    }
  return (
    <VStack boxShadow={'0 0 7px rgba(0,0,0,0.5)'} borderRadius='10px' p='3' _hover={{transform:'translateY(-5px)'}} transition={'ease-out all .3s'}>
      <Flex w='100%' fontSize={'23px'} alignItems='center' justifyContent={'space-between'}>
        <Box><SmallCloseIcon cursor={'pointer'} onClick={handelDelete}/></Box>
        <Link to={`/admin/AdminProductEdit/${item._id}`}><EditIcon cursor={'pointer'}/></Link>
      </Flex>
      <Link to={`/product/${item._id}`}>
      <Image maxW={{sm:'150px' , base:'70px'}} h={'150px'}  src={item.imageCover}/>
      </Link>
      <Text opacity={'.8'} fontSize={'17px'} w={'100%'} textAlign={"start"}  fontWeight={'semibold'}>{item.title}</Text>
      <Box display={'flex'} justifyContent={'space-between'} w='100%'>
      <Flex fontWeight={'bold'} alignItems={'center'} color={'yellow.400'}>{ item.ratingsAverage ? item.ratingsAverage : "0 " }<GiRoundStar/></Flex>
        <Text fontWeight={'bold'}>{item.price}$</Text>
      </Box>
    </VStack>
  )
}

export default AllProductsCard