import useDeleteData from "../../hooks/useDeleteData";
import { useGetData } from "../../hooks/useGetData";
import { useInsertData } from "../../hooks/useInsertData";
import {  ADD_PROMO_TOP, DELETE_PROMO, GET_ALL_PROMO_TOP, GET_ERROR } from "../types";


export const getAllPromoTop = (limit ,page)=> async(dispatch)=>{
    try {
        const res =await useGetData(`api/v1/promotop?limit=${limit}&page=${page}`)
        dispatch({
            type:GET_ALL_PROMO_TOP,
            payload:res?.data
        })
    } catch (error) {
        dispatch({
            type:GET_ERROR,
            payload:"Error : " + error?.response?.data
        })
    }
}


export const deletePromoTop= (id) => async (dispatch) => {
    try {
        const response = await useDeleteData(`/api/v1/promotop/${id}`);

        dispatch({
            type: DELETE_PROMO,
            payload: response,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
}

export const addPromoTop = (data) => async(dispatch)=>{
    
    try{
        const res =await useInsertData('api/v1/promotop' ,  data)
        
        
        dispatch({
            type: ADD_PROMO_TOP,
            payload:res,
        })
    }catch(error){
        dispatch({
            type: GET_ERROR,
            payload:"Error : " + error?.response?.data
        })
    }
    
}