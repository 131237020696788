import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { applayCoupnCart } from '../../redux/actions/cartAction';
import cartReducer from './../../redux/reducers/cartReducer';
import GetAllUserCartHook from './get-all-user-cart-hook';
import { useToast } from '@chakra-ui/react';
const ApplayCouponHook = () => {
    const dispatch = useDispatch();

   const toast = useToast()
    const [couponName, setCouponName] = useState('')
    const [loading, setLoading] = useState(true)

    const onChangeCoupon = (e) => {
        setCouponName(e)
    }

    const handelSubmitCoupon = async (e) => {
        e.preventDefault()
        if (couponName === "") {
            toast({
                title:'please enter the coupon',
                status:'error',
                duration:3000,
                isClosable:true 
                })
            return
        }
        setLoading(true)
        await dispatch(applayCoupnCart({
            couponName: couponName
        }))
        setLoading(false)
    }

    const res = useSelector(state => state.cartReducer.applayCoupon)

    useEffect(() => {

        if (loading === false) {
            
            if (res && res.status === 200) {
                toast({
                    title:'used coupon successfully',
                    status:'success',
                    duration:3000,
                    isClosable:true 
                    })
              
                    
                

            } else {
                toast({
                    title:'this coupon is wrong or expire',
                    status:'error',
                    duration:3000,
                    isClosable:true 
                    })
                
            }
        }
    }, [loading])


    return [couponName, onChangeCoupon, handelSubmitCoupon]

}

export default ApplayCouponHook