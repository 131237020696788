import { Box, Circle, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next';

function UserOrderItem({item}) {
  const { t, i18n } = useTranslation();

  return (
    <Box w='100%'   display={'flex'} gap="4px" >
        <Image alignSelf={'center'} h={{lg:'160px' , md:'120px', base:"100px"}} w={{lg:'150px', md:'110px' , sm:'100px',base:'30%'}} src={item?.product?.imageCover}/>
        <Flex flexDir={'column'} gap="8px" p='12px' w={{sm:'100%' ,base:'70%'}}>
            
                <Text fontSize={{lg:'19px',md:'15px'}} display={{sm:'block' , base:'none'}} fontWeight='bold'>{item?.product?.title}</Text>
                <Flex gap="8px" alignItems={'center'}>
                <Text fontWeight={'bold'} mb='5px'>{t("Color")} : </Text>
                <Circle h='30px' w="30px" bgColor={item?.color}></Circle>
                </Flex>
                <Box  rounded={"md"} fontSize={"18px"}>{item?.options}</Box>

                <Flex alignItems={'center'}>

                    <Text fontWeight={'semibold'} mr='5px' display={'inline'}>{t("Quantity")} : </Text>
                    <Text fontWeight={'semibold'} mr='5px' display={'inline'}>{item?.count}</Text>

                </Flex>
                
            
        </Flex>
        
    </Box>
  )
}

export default UserOrderItem