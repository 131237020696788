import { Flex, Heading, Text, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import UserOrderItem from './UserOrderItem'
import { useDispatch, useSelector } from 'react-redux'
import { getAllOrder } from '../../redux/actions/orderAction'
import Pagination from '../Utils/Pagination'
import { useTranslation } from 'react-i18next'

function UserAllOrder() {
  const { t, i18n } = useTranslation();

  const res = useSelector(state => state.orderReducer.getAllOrder)

  const onPage = (num)=>{
    dispatch(getAllOrder(2,num))
  }
  let pageCount = 0
  if(res?.paginationResult){
    pageCount = res?.paginationResult?.numberOfPages
  }
  const dispatch = useDispatch()
  useEffect(() => {
    
         dispatch(getAllOrder(2))
  
  }, [dispatch])
  
  return (
    <VStack w='100%' spacing={'20px'}>
        <Heading w='100%'>{t("Welcome")}  </Heading>
        { res?.data  ? (
                res.data.map(e=>(
                  <VStack key={e._id} p='5px' display='start' w='100%' spacing={'15px'} boxShadow={'0 0 5px rgba(0,0,0,0.2)'} borderRadius={'15px'}>
                <Text p='5px 10px' fontWeight='500' fontSize={{base:'17px' , md:'19px'}}>#{e.id}</Text>
                 {
                  e.cartItems.map((item , index)=>(
                    <UserOrderItem key={index} item={item}/>

                  ))
                 }
                 

                  <Flex w='100%'  p='5px 10px' justifyContent={'space-between'} alignItems='center'>
                    <Flex gap="4px">
                      <Text fontWeight='500' fontSize={{base:'17px' , md:'19px'}}>{t("orderStatus")} : </Text>
                      <Text fontWeight='500' fontSize={{base:'17px' , md:'19px'}}>{e.isDelivered ? t("completed") : e.cancelled? t("cancelled") :t("pending")}</Text>
                  
           
                    </Flex>
                      <Text fontWeight='500' fontSize={{base:'17px' , md:'19px'}}>{e.totalOrderPrice+3}$</Text>
                  </Flex>
                  </VStack>

                ))

        ):"no order"}
        {
      pageCount > 1 ? 
      (
        <Pagination pageCount={pageCount?pageCount : 0} onPage={onPage}/>
      ):null
    }
    
        
    </VStack>
  )
}

export default UserAllOrder