import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createNewUser, forgetPassword, loginUser } from '../../redux/actions/authAction';
import { useNavigate } from 'react-router-dom'
import { createReview, deleteReviewOnProduct } from './../../redux/actions/reviewAction';
import { useToast } from '@chakra-ui/react';
const DeleteRateHook = (review) => {
    const toast = useToast()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isUser, setIsUser] = useState(false)
    const [loading, setLoading] = useState(true)

    const [showDelete, setShowDelete] = useState(false);
    const handleClose = () => setShowDelete(false);
    const handleShow = () => setShowDelete(true);

    let user = JSON.parse(localStorage.getItem("user"))

    useEffect(() => {
        try {
            if (review.user._id === user._id || user.role ==="admin") {
                setIsUser(true);
            }
        } catch (e) { }
    }, [])


    const handelDelete = async () => {
        setLoading(true)
        await dispatch(deleteReviewOnProduct(review._id))
        setLoading(false)
        handleClose();
    }
    const res = useSelector(state => state.reviewReducer.deleteReview)

    useEffect(() => {
        if (loading === false) {
            if (res === "") {
                toast({
                    title:'review deleted successfully',
                    status:'success',
                    duration:3000,
                    isClosable:true 
                    })
                setTimeout(() => {
                    window.location.reload(false)
                }, 1000);
            }
            else
            toast({
                title:' there is problem with delete processing  ',
                status:'success',
                duration:3000,
                isClosable:true 
                })
        }
    }, [loading])

    return [isUser, handelDelete, handleShow, handleClose, showDelete]

}

export default DeleteRateHook