export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY"
export const GET_ERROR = "GET_ERROR"
export const ADD_CATEGORY = "ADD_CATEGORY"
export const GET_ALL_BRAND = "GET_ALL_BRAND"
export const ADD_BRAND = "ADD_BRAND"
export const GET_PRODUCT_DETALIS_AR = "GET_PRODUCT_DETALIS_AR"
export const GET_PRODUCT_DETALIS_EN = "GET_PRODUCT_DETALIS_EN"
export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const DELETE_BRAND = "DELETE_BRAND"

export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_BRAND = "UPDATE_BRAND"

export const GET_ONE_CATEGORY_AR = "GET_ONE_CATEGORY_AR"
export const GET_ONE_BRAND_AR = "GET_ONE_BRAND_AR"

export const GET_ONE_CATEGORY_EN = "GET_ONE_CATEGORY_EN"
export const GET_ONE_BRAND_EN = "GET_ONE_BRAND_EN"

export const GET_ALL_PRODUCTS_HOME = "GET_ALL_PRODUCTS_HOME"

export const GET_ALL_PROMO = "GET_ALL_PROMO"
export const ADD_PROMO = "ADD_PROMO"
export const DELETE_PROMO = "DELETE_PROMO"

export const GET_ALL_PROMO_TOP = "GET_ALL_PROMO_TOP"
export const ADD_PROMO_TOP = "ADD_PROMO_TOP"
export const DELETE_PROMO_TOP = "DELETE_PROMO_TOP"

export const GET_ALL_SLIDER = "GET_ALL_SLIDER"
export const ADD_SLIDER = "ADD_SLIDER"
export const DELETE_SLIDER = "DELETE_SLIDER"

export const CREATE_ORDER_CASH = "CREATE_ORDER_CASH"
export const GET_ALL_ORDER = "GET_ALL_ORDER"
export const GET_ONE_ORDER = "GET_ONE_ORDER"
export const COMPLETE_ORDER = "COMPLETE_ORDER"
export const CANCEL_ORDER = "CANCEL_ORDER"

export const CREATE_SUB_CATEGORY = "CREATE_SUB_CATEGORY"
export const GET_SUB_CATEGORY = "GET_SUB_CATEGORY"
export const CREATE_PRODUCTS = "CREATE_PRODUCTS"
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS"
export const GET_PRODUCT_DETALIS = "GET_PRODUCT_DETALIS"
export const GET_ONE_CATEGORY = "GET_ONE_CATEGORY"
export const GET_ONE_BRAND = "GET_ONE_BRAND"
export const GET_PRODUCT_LIKE = "GET_PRODUCT_LIKE"
export const DELETE_PRODUCTS = "DELETE_PRODUCTS"
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS"
export const CREATE_NEW_USER = "CREATE_NEW_USER"
export const LOGIN_USER = "LOGIN_USER"
export const GET_CURERNT_USER = "GET_CURERNT_USER"
export const FOREGT_PASSWORD = "FOREGT_PASSWORD"
export const VERIFY_PASSWORD = "VERIFY_PASSWORD"
export const RESET_PASSWORD = "RESET_PASSWORD"
export const CREATE_REVIEW = "CREATE_REVIEW"
export const ALL_REVIEW_PRODUCT = "ALL_REVIEW_PRODUCT"
export const DELETE_REVIEW = "DELETE_REVIEW"
export const UPDATE_REVIEW = "UPDATE_REVIEW"
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST"
export const REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST"
export const USER_WISHLIST = "USER_WISHLIST"
export const ADD_COUPON = "ADD_COUPON"
export const GET_ALL_COUPON = "GET_ALL_COUPON"
export const DELTET_COUPON = "DELTET_COUPON"
export const GET_ONE_COUPON = "GET_ONE_COUPON"
export const EDIT_COUPON = "EDIT_COUPON"
export const ADD_USER_ADDRESS = "ADD_USER_ADDRESS"
export const GET_ALL_USER_ADDRESS = "GET_ALL_USER_ADDRESS"
export const DELETE_USER_ADDRESS = "DELETE_USER_ADDRESS"
export const GET_ONE_USER_ADDRESS = "GET_ONE_USER_ADDRESS"
export const EDIT_USER_ADDRESS = "EDIT_USER_ADDRESS"
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE"
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD"
export const ADD_TO_CART = "ADD_TO_CART"
export const GET_ALL_USER_CART = "GET_ALL_USER_CART"
export const CLEAR_ALL_USER_CART = "CLEAR_ALL_USER_CART"
export const UPDATE_ITEM_FROMCART = "UPDATE_ITEM_FROMCART"
export const DELETE_ITEM_FROMCART = "DELETE_ITEM_FROMCART"
export const APPALY_COUPON_CART = "APPALY_COUPON_CART"