import "./i18n";
import { useTranslation } from "react-i18next";
import ArImage from "./images/ar.png";
import EnImage from "./images/en.png";

import Home from "./pages/Home/Home";
import NavBarLogin from "./components/Utils/NavBarLogin";
import { Routes, Route, Link } from "react-router-dom";
import LoginPage from "./pages/Auth/LoginPage";
import AllCategory from "./pages/Category/AllCategory";
import {
  Box,
  Button,
  Circle,
  Container,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorMode,
} from "@chakra-ui/react";
import AllBrands from "./pages/Brand/AllBrands";
import ProductShop from "./pages/Products/ProductShop";
import ProductDetails from "./pages/Products/ProductDetails";
import Cart from "./pages/Cart/Cart";
import PaymentMethods from "./pages/Payment/PaymentMethods";
import AdminAllProducts from "./pages/Admin/AdminAllProducts";
import AdminAllOrders from "./pages/Admin/AdminAllOrders";
import AdminOrerDetails from "./pages/Admin/AdminOrerDetails";
import AdminAddBrand from "./pages/Admin/AdminAddBrand";
import AdminAddCategory from "./pages/Admin/AdminAddCategory";
import LBImage from "./images/LB.png";

import AdminAddProduct from "./pages/Admin/AdminAddProduct";
import UserAllOrders from "./pages/user/UserAllOrders";
import UserFavoriteProducts from "./pages/user/UserFavoriteProducts";
import UserAddresses from "./pages/user/UserAddresses";
import UserProfile from "./pages/user/UserProfile";
import AdminProductEdit from "./pages/Admin/AdminProductEdit";
import AdminAddSlider from "./pages/Admin/AdminAddSlider";
import AdminAddPromos from "./pages/Admin/AdminAddPromos";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAllPromoTop } from "./redux/actions/promoTopAction";
import Footer from "./components/Utils/Footer";
import ShippingPolicy from "./pages/policy/ShippingPolicy";
import AdminCoupon from "./pages/Admin/AdminCoupon";
import EditCategory from "./components/admin/EditCategory";
import AdminEditCategory from "./pages/Admin/AdminEditCategory";
import AdminEditBrand from "./pages/Admin/AdminEditBrand";
function App() {
  const { colorMode, toggleColorMode } = useColorMode();
  const isDark = colorMode === "dark";
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const resTop = useSelector((state) => state.promoTopReducer.promoTop);
  useEffect(() => {
    dispatch(getAllPromoTop());

    i18n.changeLanguage(localStorage.getItem("lang"));
  }, []);
  return (
    <div dir={i18n.language === "ar" ? "rtl" : "ltr"}>
      {resTop?.data?.length
        ? resTop.data.map((e) => (
            <Flex
              key={e._id}
              flexDir={{ md: "row", base: "column" }}
              fontSize={{ base: "15px", md: "18px" }}
              gap="10px"
              py={"10px"}
              fontWeight={"bold"}
              textColor={"white"}
              bgColor={"#1a202c"}
              justifyContent={"center"}
              alignItems={"center"}
              textAlign={"center"}
              color={isDark ? "#faf089" : "white"}
            >
              <Image w="30px" h="30px" src={LBImage} alt={"logo lebanon"} />
              {e.name}
            </Flex>
          ))
        : null}

      <NavBarLogin />
      <Container
        minH={"100vh"}
        maxW={{
          xl: "container.xl",
          lg: "container.lg",
          md: "container.md",
          sm: "container.sm",
          base: "400px",
        }}
      >
        <Routes>
          <Route index element={<Home />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/allcategory" element={<AllCategory />} />
          <Route path="/allbrands" element={<AllBrands />} />
          <Route path="/allproducts" element={<ProductShop />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/order/payment" element={<PaymentMethods />} />
          <Route path="/admin/allproducts" element={<AdminAllProducts />} />
          <Route path="/admin/allorders" element={<AdminAllOrders />} />
          <Route path="/admin/order/:id" element={<AdminOrerDetails />} />
          <Route path="/admin/addbrand" element={<AdminAddBrand />} />
          <Route path="/admin/addcategory" element={<AdminAddCategory />} />
          <Route path="/admin/addslider" element={<AdminAddSlider />} />
          <Route path="/admin/addpromo" element={<AdminAddPromos />} />
          <Route path="/admin/coupon" element={<AdminCoupon />} />

          <Route
            path="/admin/AdminProductEdit/:id"
            element={<AdminProductEdit />}
          />
          <Route
            path="/admin/AdminCategoryEdit/:id"
            element={<AdminEditCategory />}
          />
          <Route
            path="/admin/AdminBrandEdit/:id"
            element={<AdminEditBrand />}
          />

          <Route path="/admin/addproduct" element={<AdminAddProduct />} />
          <Route path="/user/allorders" element={<UserAllOrders />} />
          <Route
            path="/user/favoriteproducts"
            element={<UserFavoriteProducts />}
          />
          <Route path="/user/address" element={<UserAddresses />} />
          <Route path="/user/profile" element={<UserProfile />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
        </Routes>
        <Box
          position={"fixed"}
          bottom={{ md: "140px", base: "75px" }}
          right={{ md: "40px", base: "20px" }}
          zIndex={"5"}
        >
          <Menu>
            <MenuButton>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="50"
                height="50"
                viewBox="0 0 48 48"
              >
                <path
                  fill="#fff"
                  d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
                ></path>
                <path
                  fill="#fff"
                  d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
                ></path>
                <path
                  fill="#cfd8dc"
                  d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
                ></path>
                <path
                  fill="#40c351"
                  d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
                ></path>
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </MenuButton>
            <MenuList bgColor={"white"}>
              <a href="https://wa.me/96171857671">
                <MenuItem>خاص للاحذية</MenuItem>
              </a>
              <a href="https://wa.me/96176472296">
                <MenuItem>اكسسوارات و غيره</MenuItem>
              </a>
            </MenuList>
          </Menu>
        </Box>

        {i18n.language == "en" && (
          <Box
            cursor={"pointer"}
            onClick={() => {
              i18n.changeLanguage("ar");
              localStorage.setItem("lang", "ar");
              window.location.reload();
            }}
            position={"fixed"}
            bottom={{ md: "80px", base: "20px" }}
            width={"40px"}
            height={"40px"}
            right={{ md: "46px", base: "25px" }}
            zIndex={"4"}
          >
            <Image src={ArImage} />
          </Box>
        )}
        {i18n.language == "ar" && (
          <Box
            cursor={"pointer"}
            onClick={() => {
              i18n.changeLanguage("en");
              localStorage.setItem("lang", "en");
              window.location.reload();
            }}
            position={"fixed"}
            bottom={{ md: "80px", base: "20px" }}
            width={"40px"}
            height={"40px"}
            right={{ md: "46px", base: "25px" }}
            zIndex={"4"}
          >
            <Image src={EnImage} />
          </Box>
        )}
      </Container>
      <Footer />
    </div>
  );
}

export default App;
