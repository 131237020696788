import { Flex, Text, VStack } from '@chakra-ui/react'
import {GiRoundStar} from 'react-icons/gi'
import React from 'react'
import RateItem from './RateItem'
import RatePost from './RatePost'
import ViewAllReviewHook from '../../hook/review/view-all-review-hook'
import { useParams } from 'react-router-dom'
import Pagination from '../Utils/Pagination'
import { useTranslation } from 'react-i18next'

const Rate = ({rateAvg , rateQty}) => {
  const { t, i18n } = useTranslation();

  const { id } = useParams()
    const [allReview, onPress] = ViewAllReviewHook(id)

  return (
    <VStack w='100%' borderRadius={'10px'} boxShadow='0 0 3px rgba(0,0,0,0.5)' p='30px'>
        <Flex w='100%' alignItems={'center'} gap={'5px'}>
            <Text fontWeight={'bold'} fontSize={'25px'} >{t("rate")}</Text>
            <Flex fontWeight={'bold'} alignItems={'center'} color={'yellow.400'}>{rateAvg ? rateAvg : "0"}<GiRoundStar/></Flex>
            <Text opacity={'.7'}>({rateQty ? rateQty : "0"} {t("rate")})</Text>
        </Flex>
        <RatePost/>

        {allReview?.data?.length > 0  ? (allReview.data.map((review, index) => {
                return (<RateItem key={index} review={review} />)
            })) : <h6>{t("comment")}</h6>}


            {
                allReview.paginationResult && allReview.paginationResult.numberOfPages > 6 ? (<Pagination pageCount={allReview.paginationResult ? allReview.paginationResult.numberOfPages : 0} onPage={onPress} />) : null
            }
        
    </VStack>
  )
}

export default Rate