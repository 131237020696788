import { VStack, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import SubTittle from '../Utils/SubTittle'
import ProductCard from './ProductCard'
import CardContainerHook from '../../hook/products/card-container-hook'

const Product = ({title,btntitle , items}) => {
  const [favProd] = CardContainerHook()
  
  return (
    <VStack mb='60px' w='100%'>
    <SubTittle title={title} btntitle={btntitle} pathText={'/allproducts'}/>
    <SimpleGrid columns={{xl:[5] , lg:[4] , md :[3] , sm:[2] , base:[2]}} spacing={{md:'36px' , base:"15px"}} w='100%'>
      {
        items ? items.map((item ,index )=>(
          <ProductCard favProd={favProd} key={index} item={item}/>
        )):""
      }
        
        
    </SimpleGrid>
    </VStack>
  )
}

export default Product