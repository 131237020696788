

import { useInsertData } from '../../hooks/useInsertData'
import {   useGetDataToken } from './../../hooks/useGetData';
import { useInsUpdateData } from '../../hooks/useUpdateData';
import { CANCEL_ORDER, COMPLETE_ORDER, CREATE_ORDER_CASH, GET_ALL_ORDER, GET_ERROR } from '../types';


export const createOrder = (cartID, body) => async (dispatch) => {
    
    try {
       
        const response = await useInsertData(`/api/v1/orders/${cartID}` , body)
        
        dispatch({
            type: CREATE_ORDER_CASH,
            payload:response,

        })
    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: e.response,
        })
    }
}

export const getAllOrder = (limit ,page) => async (dispatch)=>{
    try {
        
        const response = await useGetDataToken(`/api/v1/orders?limit=${limit}&page=${page}`)
     

        dispatch({
            type: GET_ALL_ORDER,
            payload:response,

        })
    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: e.response,
        })
    }
}


export const completeOrder = (id ) => async (dispatch)=>{
    try {
        
        const response = await useInsUpdateData(`/api/v1/orders/${id}/deliver`)
     

        dispatch({
            type: COMPLETE_ORDER,
            payload:response,

        })
    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: e.response,
        })
    }
}

export const cancelOrder = (id ) => async (dispatch)=>{
    try {
        
        const response = await useInsUpdateData(`/api/v1/orders/${id}/cancelled`)
  

        dispatch({
            type: CANCEL_ORDER,
            payload:response,

        })
    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: e.response,
        })
    }
}