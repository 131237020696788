import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { Link } from "react-router-dom";
import baseUrl from "../../api/baseUrl";
function NavBurger({ logOut, user }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [res, setRes] = useState([]);
  useEffect(() => {
    const onPage = async () => {
      try {
        const response = await baseUrl.get("api/v1/categories?limit=12");
        setRes(response);
      } catch (error) {
        throw error;
      }
    };
    onPage();
  }, []);

  return (
    <>
      <IconButton icon={<HamburgerIcon />} onClick={onOpen} />
      <Drawer onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <Flex alignItems={"center"} justifyContent="space-between">
              <GrFormClose onClick={onClose} />
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <VStack fontSize={"20px"} fontWeight={"600"} display="start">
              <Link to="/" className="link">
                <Text py="8px">{t("home")}</Text>
              </Link>
              {user != "" && (
                <VStack display="start">
                  {user.role === "admin" ? (
                    <a href="/admin/allproducts" className="link">
                      {t("dashboard")} <Divider />
                    </a>
                  ) : (
                    <a href="/user/profile" className="link">
                      {t("Profile")}
                      <Divider />
                    </a>
                  )}
                </VStack>
              )}

              <Link to="/cart" className="link">
                <Text py="8px">{t("cart")}</Text>
              </Link>
              <Divider />

              {res?.data?.data?.length > 0 &&
                res?.data?.data?.map((category) => (
                  <a
                    key={category._id}
                    href={`/allproducts?idCategory=${category._id}`}
                    className="link"
                  >
                    <Text py="8px">{category.name}</Text>
                    <Divider />
                  </a>
                ))}

              {user != "" && <Text onClick={logOut}>{t("logout")}</Text>}

              {user === "" && (
                <a href="/login" className="link">
                  <Text>{t("login")}</Text>
                </a>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default NavBurger;
