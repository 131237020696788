import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addCoupon, getAllCoupon } from "../../redux/actions/couponAction";
import { useToast } from "@chakra-ui/react";

const AddCouponHook = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [coupnName, setCoupnName] = useState("");
  const [couponDate, setCouponDate] = useState("");
  const [couponValue, setCouponValue] = useState(1);
  const [loading, setLoading] = useState(true);

  const onChangeName = (event) => {
    event.persist();
    setCoupnName(event.target.value);
  };

  const onChangeDate = (event) => {
    event.persist();
    setCouponDate(event.target.value);
  };
  const onChangeValue = (event) => {
    event.persist();
    setCouponValue(event.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (coupnName === "" || couponDate === "" || couponValue <= 0) {
      toast({
        title: " please fill all fields ",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    } else {
      setLoading(true);
      await dispatch(
        addCoupon({
          name: coupnName,
          expire: couponDate,
          discount: couponValue,
        })
      );
      setLoading(false);
    }
  };

  const res = useSelector((state) => state.couponReducer.addCoupon);
  const dele = useSelector((state) => state.couponReducer.deleteCoupon);
  useEffect(() => {
    if (loading === false) {
      if (res && res.status === 201) {
        toast({
          title: "coupon add successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (res && res.status === 400) {
        toast({
          title: "this coupon already found",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else if (res && res.status === 403) {
        toast({
          title: "you do not have access to create a coupon ",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [loading]);

  useEffect(() => {
    const get = async () => {
      await dispatch(getAllCoupon());
    };
    get();
  }, [res, dele]);

  const allCoupon = useSelector((state) => state.couponReducer.allCoupon);

  let coupons = [];
  try {
    if (allCoupon && allCoupon.data.length >= 1) coupons = allCoupon.data;
  } catch (e) {}

  return [
    coupnName,
    couponDate,
    couponValue,
    onChangeName,
    onChangeDate,
    onChangeValue,
    onSubmit,
    coupons,
  ];
};

export default AddCouponHook;
