import {
  Box,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ApplayCouponHook from "../../hook/cart/applay-coupon-hook";
import DeleteCartHook from "../../hook/cart/delete-cart-hook";
import ViewAddressesHook from "../../hook/user/view-addresses-hook";
import { useDispatch, useSelector } from "react-redux";
import { getOneUserAddress } from "../../redux/actions/userAddressesAction";
import { createOrder } from "../../redux/actions/orderAction";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CartCheckout = ({
  totalCartPrice,
  totalCartPriceAfterDiscount,
  couponNameRes,
  id,
}) => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState("");
  const [phone, setPhone] = useState("");
  const [alias, setAlias] = useState("");

  const fakeUser = localStorage.getItem("fakeUser");

  const resOrder = useSelector((state) => state?.orderReducer?.createOrder);

  const handleSubmit = async () => {
    const shippingAddress = {
      details,
      phone,
      alias,
    };

    if (details === "" && phone === "") {
      toast({
        title: "please add your phone and your address",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      await dispatch(createOrder(id, { shippingAddress, fakeUser }));
    }

    setLoading(false);
  };

  useEffect(() => {
    if (loading == false) {
      if (resOrder?.status == 201) {
        toast({
          title: "order completed successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.href = "/user/allorders";
        }, 1000);
      }
    }
  }, [loading, resOrder]);

  const { colorMode } = useColorMode();
  const isDark = colorMode === "dark";
  const [handelDeleteCart] = DeleteCartHook();

  const [couponName, onChangeCoupon, handelSubmitCoupon] = ApplayCouponHook();

  useEffect(() => {
    if (couponNameRes) {
      onChangeCoupon(couponNameRes);
    }
  }, [couponNameRes]);
  return (
    <Box
      borderRadius={"5px"}
      p="4"
      boxShadow={"0 0 5px rgba(0,0,0,0.2)"}
      mb={{ base: "20px", md: "0" }}
    >
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Choose Address </ModalHeader>
          <ModalCloseButton />
          <ModalBody gap={"2px"}>
            <Input
              required
              onChange={(e) => {
                setAlias(e.target.value);
              }}
              value={alias}
              mb="10px"
              placeholder="Enter Your Name"
              w="100%"
              variant={"unstyled"}
              border={"3px solid rgba(0,0,0,0.1)"}
              p="4.5px 16px"
              fontSize={"17px"}
              fontWeight={"semibold"}
              opacity=".8"
            />
            <Input
              required
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              value={phone}
              mb="10px"
              placeholder="Enter Your Phone"
              w="100%"
              variant={"unstyled"}
              border={"3px solid rgba(0,0,0,0.1)"}
              p="4.5px 16px"
              fontSize={"17px"}
              fontWeight={"semibold"}
              opacity=".8"
            />
            <Input
              required
              onChange={(e) => {
                setDetails(e.target.value);
              }}
              value={details}
              mb="10px"
              placeholder="Enter Your Address"
              w="100%"
              variant={"unstyled"}
              border={"3px solid rgba(0,0,0,0.1)"}
              p="4.5px 16px"
              fontSize={"17px"}
              fontWeight={"semibold"}
              opacity=".8"
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Back
            </Button>

            <Button onClick={handleSubmit} variant="ghost">
              Go
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <FormControl mb="10px">
        <InputGroup display={"flex"} alignItems="center">
          <Input
            value={couponName}
            onChange={(e) => onChangeCoupon(e.target.value)}
            placeholder={t("AddCoupon")}
            type="text"
            variant={"unstyled"}
            border={"3px solid #ECC94B"}
            p="4.5px 16px"
            fontWeight={"semibold"}
            opacity=".8"
          />
          <InputRightElement w="54px">
            <Button h="91%" onClick={handelSubmitCoupon} colorScheme={"yellow"}>
              {t("Submit")}
            </Button>
          </InputRightElement>
        </InputGroup>
      </FormControl>
      <Text
        color={isDark ? "#ECC94B" : "rgba(0,0,0,1)"}
        borderRadius={"5px"}
        textAlign={"center"}
        p="3"
        border={"4px solid rgba(0,0,0,0.2)"}
        w="100%"
        fontWeight={"500"}
      >
        {totalCartPriceAfterDiscount >= 1
          ? `${totalCartPrice}$  after discount   ${totalCartPriceAfterDiscount} `
          : `${totalCartPrice} `}
        $
      </Text>
      <Button onClick={onOpen} mt="40px" p="3" w="100%" colorScheme={"yellow"}>
        {t("CompleteOrder")}
      </Button>
      <Text
        color={isDark ? "#ECC94B" : "rgba(0,0,0,1)"}
        borderRadius={"5px"}
        textAlign={"center"}
        p="3"
        w="100%"
        fontWeight={"500"}
      >
        {t("FinalWithDelivery")}
        {totalCartPriceAfterDiscount >= 1
          ? ` ${totalCartPriceAfterDiscount + 3} `
          : ` ${totalCartPrice + 3} `}
        $
      </Text>
    </Box>
  );
};

export default CartCheckout;
