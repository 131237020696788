import { Box, HStack } from "@chakra-ui/react";
import React from "react";
import ProductGalleryDesign from "./ProductGalleryDesign";
import ProductText from "./ProductText";

const ProductDetail = () => {
  return (
    <HStack w="100%" flexDir={{ base: "column", md: "row" }}>
      <Box w={{ lg: "30%", md: "35%", base: "100%" }}>
        <ProductGalleryDesign />
      </Box>
      <Box w={{ lg: "70%", base: "100%" }}>
        <ProductText />
      </Box>
    </HStack>
  );
};

export default ProductDetail;
