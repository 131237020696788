import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

import React, { useState } from "react";
import MultiImageInput from "react-multiple-image-input";
import add from "../../images/add.png";
import { CompactPicker } from "react-color";
import AdminEditProductsHook from "../../hook/products/edit-products-hook";
import { EditIcon, SmallCloseIcon } from "@chakra-ui/icons";
const EditProduct = ({ id }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [nameOptions, setNameOptions] = useState("");
  const [quantityOptions, setQuantityOptions] = useState(0);
  const [nameOptionsEdit, setNameOptionsEdit] = useState("");
  const [editIndex, setEditIndex] = useState();
  const [quantityOptionsEdit, setQuantityOptionsEdit] = useState(0);
  const [
    CatID,
    BrandID,
    onChangeDesName,
    onChangeQty,
    onChangeColor,
    onChangePriceAfter,
    onChangePriceBefor,
    onChangeProdName,
    showColor,
    category,
    brand,
    priceAftr,
    images,
    setImages,
    onSelect,
    onRemove,
    options,
    handelChangeComplete,
    removeColor,
    onSeletCategory,
    handelSubmit,
    onSeletBrand,
    colors,
    priceBefore,
    qty,
    prodDescription,
    prodName,
    prodNameEn,
    onChangeProdNameEn,
    prodDescriptionEn,
    onChangeDesNameEn,
    inputOptions,
    setInputOptions,
    optionSize,
    setOptionSize,
    imageCover,
    onImageChange,
  ] = AdminEditProductsHook(id);
  const handleEditOption = (index) => {
    const editedOption = optionSize[index];
    setNameOptionsEdit(editedOption.name);
    setQuantityOptionsEdit(editedOption.quantity);
    setEditIndex(index); // Store the index of the option being edited
    onOpen(); // Open the modal
  };
  const handleSaveOption = () => {
    const updatedOption = {
      name: nameOptionsEdit,
      quantity: quantityOptionsEdit,
    };
    const updatedOptions = [...optionSize];
    updatedOptions[editIndex] = updatedOption;
    setOptionSize(updatedOptions);
    onClose(); // Close the modal
  };
  return (
    <VStack spacing={"20px"} display="start" w="100%">
      <Heading>Edit Product</Heading>

      <FormControl isRequired>
        <label htmlFor="upload-photo">
          <Image
            mb="30px"
            src={imageCover}
            cursor={"pointer"}
            w={{ md: "260px", base: "210" }}
            h={{ md: "200px", base: "150px" }}
          />
        </label>
        <Input
          w="0"
          variant={"unstyled"}
          type="file"
          name="photo"
          onChange={onImageChange}
          id="upload-photo"
        />
      </FormControl>

      <HStack spacing={"10px"}>
        {colors.length >= 1
          ? colors.map((color, index) => {
              return (
                <Box
                  bgColor={color}
                  w={"30px"}
                  h="30px"
                  borderRadius={"100%"}
                  key={index}
                  onClick={() => removeColor(color)}
                  className="color ms-2 border  mt-1"
                ></Box>
              );
            })
          : null}

        <img
          onClick={onChangeColor}
          src={add}
          alt=""
          width="30px"
          height="35px"
          style={{ cursor: "pointer" }}
        />
        {showColor === true ? (
          <CompactPicker onChangeComplete={handelChangeComplete} />
        ) : null}
      </HStack>
      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList>
          <Tab>ِAR</Tab>
          <Tab>EN</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={"0px"}>
            <FormControl isRequired>
              <Input
                value={prodName}
                onChange={onChangeProdName}
                type="text"
                placeholder="name"
                w="100%"
                variant={"unstyled"}
                border={"3px solid rgba(0,0,0,0.1)"}
                p="4.5px 16px"
                fontSize={"17px"}
                fontWeight={"semibold"}
                opacity=".8"
              />
            </FormControl>
          </TabPanel>
          <TabPanel px={"0px"}>
            <FormControl isRequired>
              <Input
                onChange={onChangeProdNameEn}
                value={prodNameEn}
                placeholder="name english"
                w="100%"
                variant={"unstyled"}
                border={"3px solid rgba(0,0,0,0.1)"}
                p="4.5px 16px"
                fontSize={"17px"}
                fontWeight={"semibold"}
                opacity=".8"
              />
            </FormControl>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList>
          <Tab>ِAR</Tab>
          <Tab>EN</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={"0px"}>
            <FormControl isRequired>
              <Textarea
                value={prodDescription}
                onChange={onChangeDesName}
                placeholder="Description..."
                w="100%"
                variant={"unstyled"}
                border={"3px solid rgba(0,0,0,0.1)"}
                p="6px 16px"
                fontSize={"17px"}
                fontWeight={"semibold"}
                opacity=".8"
              />
            </FormControl>
          </TabPanel>
          <TabPanel px={"0px"}>
            <FormControl isRequired>
              <Textarea
                value={prodDescriptionEn}
                onChange={onChangeDesNameEn}
                placeholder="Description... English"
                w="100%"
                variant={"unstyled"}
                border={"3px solid rgba(0,0,0,0.1)"}
                p="6px 16px"
                fontSize={"17px"}
                fontWeight={"semibold"}
                opacity=".8"
              />
            </FormControl>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Edit Option </ModalHeader>
          <ModalCloseButton />
          <ModalBody gap={"2px"}>
            <Input
              required
              onChange={(e) => {
                setNameOptionsEdit(e.target.value);
              }}
              value={nameOptionsEdit}
              mb="10px"
              placeholder="Enter Your Phone"
              w="100%"
              variant={"unstyled"}
              border={"3px solid rgba(0,0,0,0.1)"}
              p="4.5px 16px"
              fontSize={"17px"}
              fontWeight={"semibold"}
              opacity=".8"
            />
            <Input
              required
              onChange={(e) => {
                setQuantityOptionsEdit(e.target.value);
              }}
              type="number"
              min="0"
              value={quantityOptionsEdit}
              mb="10px"
              placeholder="Enter Your Address"
              w="100%"
              variant={"unstyled"}
              border={"3px solid rgba(0,0,0,0.1)"}
              p="4.5px 16px"
              fontSize={"17px"}
              fontWeight={"semibold"}
              opacity=".8"
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Back
            </Button>

            <Button onClick={handleSaveOption} variant="ghost">
              Edit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex gap={"8px"}>
        {optionSize?.length >= 1
          ? optionSize.map((option, index) => (
              <Box
                opacity={".5"}
                rounded={"md"}
                fontSize={"18px"}
                boxShadow={"0px 0px 5px rgba(0,0,0,0.4)"}
                px={"15px"}
                key={index}
              >
                <Flex
                  w="100%"
                  fontSize={"23px"}
                  alignItems="center"
                  justifyContent={"space-between"}
                >
                  <Box
                    onClick={() => {
                      const newOptionSize = optionSize.filter(
                        (e) => e !== option
                      );
                      setOptionSize(newOptionSize);
                    }}
                  >
                    <SmallCloseIcon fontSize={"18px"} cursor={"pointer"} />
                  </Box>
                  <Box>
                    <EditIcon
                      onClick={() => handleEditOption(index)}
                      fontSize={"18px"}
                      cursor={"pointer"}
                    />
                  </Box>
                </Flex>
                <Text>name : {option.name}</Text>
                <Text>quantity : {option.quantity}</Text>
              </Box>
            ))
          : null}
      </Flex>

      <FormControl>
        <Input
          onChange={(e) => {
            setNameOptions(e.target.value);
          }}
          value={nameOptions}
          mb="10px"
          placeholder=" name option"
          w="100%"
          variant={"unstyled"}
          border={"3px solid rgba(0,0,0,0.1)"}
          p="4.5px 16px"
          fontSize={"17px"}
          fontWeight={"semibold"}
          opacity=".8"
        />
        <Input
          onChange={(e) => {
            setQuantityOptions(e.target.value);
          }}
          value={quantityOptions}
          min="0"
          mb="10px"
          type="number"
          placeholder=" quantity option"
          w="100%"
          variant={"unstyled"}
          border={"3px solid rgba(0,0,0,0.1)"}
          p="4.5px 16px"
          fontSize={"17px"}
          fontWeight={"semibold"}
          opacity=".8"
        />

        <Button
          onClick={() => {
            setNameOptions("");
            setQuantityOptions(0);
            const data = { name: nameOptions, quantity: quantityOptions };
            setOptionSize([...optionSize, data]);
          }}
          colorScheme={"yellow"}
        >
          add{" "}
        </Button>
      </FormControl>
      <Input
        min="0"
        value={priceBefore}
        onChange={onChangePriceBefor}
        placeholder="price  "
        w="100%"
        variant={"unstyled"}
        border={"3px solid rgba(0,0,0,0.1)"}
        type="number"
        p="4.5px 16px"
        fontSize={"17px"}
        fontWeight={"semibold"}
        opacity=".8"
      />

      <Input
        min="0"
        value={qty}
        onChange={onChangeQty}
        type="number"
        placeholder="quantity"
        w="100%"
        variant={"unstyled"}
        border={"3px solid rgba(0,0,0,0.1)"}
        p="4.5px 16px"
        fontSize={"17px"}
        fontWeight={"semibold"}
        opacity=".8"
      />
      <Select
        onChange={onSeletCategory}
        opacity=".8"
        _focus={{ border: "none" }}
        variant={"filled"}
      >
        <option value="0"> choose category</option>
        {category?.data
          ? category?.data?.map((item, index) => {
              return (
                <option key={index} value={item._id}>
                  {item.name}
                </option>
              );
            })
          : null}
      </Select>
      <Select
        onChange={onSeletBrand}
        opacity=".8"
        _focus={{ border: "none" }}
        variant={"filled"}
      >
        <option value="0"> choose brand</option>
        {brand?.data
          ? brand?.data?.map((item, index) => {
              return (
                <option key={index} value={item._id}>
                  {item.name}
                </option>
              );
            })
          : null}
      </Select>

      <Button onClick={handelSubmit} colorScheme={"yellow"}>
        Save{" "}
      </Button>
    </VStack>
  );
};

export default EditProduct;
