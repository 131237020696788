import { ADD_CATEGORY, DELETE_CATEGORY, GET_ALL_CATEGORY, GET_ERROR, GET_ONE_CATEGORY, GET_ONE_CATEGORY_AR, GET_ONE_CATEGORY_EN, UPDATE_CATEGORY } from "../types"

const initial = {
    category:[],
    deleteCategory:[],
    createCategory:[],
    updateCategory:[],
    getCategoryAr:[],
    getCategoryEn:[],
    loading:true
}

const categoryReducer = (state = initial , action) =>{
    switch(action.type){
        case GET_ALL_CATEGORY :
            return {
                ...state,
                category:action.payload,
                loading:false
            }
            
        case GET_ONE_CATEGORY:
            return {
                oneCategory: action.payload,
                loading: false,
            }


            case GET_ONE_CATEGORY_AR:
                return {
                    getCategoryAr: action.payload,
                    loading: false,
                }

                case GET_ONE_CATEGORY_EN:
                    return {
                        getCategoryEn: action.payload,
                        loading: false,
                    }

                    case UPDATE_CATEGORY:
                        return {
                            updateCategory: action.payload,
                            loading: false,
                        }
            
        
        case ADD_CATEGORY :
            return {
                createCategory:action.payload,
                loading:false
            }

        case DELETE_CATEGORY :
            return {
                deleteCategory:action.payload,
                loading:false
            }

        case GET_ERROR :
            return {
                    error:action.payload,
                    loading:true
            }

        default : return state
    }
}

export default categoryReducer