
import {  Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay,  useDisclosure, VStack } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next';

const UserEditProfile = ({handelSubmit, name, email, phone, onChangeName, onChangeEmail, onChangePhone}) => {
  const { t, i18n } = useTranslation();
   
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <VStack >
        <Button onClick={onOpen} colorScheme={'yellow'} w='100%'>{t("edit")}</Button>
        <Modal isOpen={isOpen} onClose={onClose} isCentered size={{base:'xs' , md:'md'}}>
        <ModalOverlay />
        <ModalContent dir={i18n.language === 'ar' ? "rtl" : "ltr" }>
          <ModalHeader>{t("EditYourProfile")}</ModalHeader>
          <ModalCloseButton left={i18n.language === 'ar' && "var(--chakra-space-3)"}  right={i18n.language === 'ar' ? "" : "var(--chakra-space-3)"}/>
          <ModalBody>
            <VStack dir={i18n.language === 'ar' ? "rtl" : "ltr" } spacing={'17px'} display='start'>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>{t("userName")}</FormLabel>
                <Input 
                value={name}
                onChange={onChangeName}
                type='text'
                placeholder={t("enterName")}
                w='100%'
                variant={'unstyled'}
                border={'3px solid rgba(0,0,0,0.1)'}
                p='4.5px 16px'
                fontSize={'17px'}
                fontWeight={'semibold'}
                opacity='.8'
                />
            </FormControl>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>{t("phone")}</FormLabel>
                <Input 
                value={phone}
                onChange={onChangePhone}
                type='text'
                placeholder={t("enterPhone")}
                w='100%'
                variant={'unstyled'}
                border={'3px solid rgba(0,0,0,0.1)'}
                p='4.5px 16px'
                fontSize={'17px'}
                fontWeight={'semibold'}
                opacity='.8'
                />
            </FormControl>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>{t("email")}</FormLabel>
                <Input 
                value={email}
                onChange={onChangeEmail}
                type='email'
                placeholder={t("enterEmail")}
                w='100%'
                variant={'unstyled'}
                border={'3px solid rgba(0,0,0,0.1)'}
                p='4.5px 16px'
                fontSize={'17px'}
                fontWeight={'semibold'}
                opacity='.8'
                />
            </FormControl>
              <Button w='100%' onClick={handelSubmit} colorScheme={'yellow'}>{t("edit")}</Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  )
}

export default UserEditProfile