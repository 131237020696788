import baseUrl from "../api/baseUrl"

export const useInsertDataWithImage =async(url,data)=>{
    const config = {
        headers: {
            "Content-Type": "multipart/form-data", 
            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    }
    try {
        const res =await baseUrl.post(url, data , config) 
   
        return res
    } catch (error) {
        return error
    }  
    
    
}

export const useInsertData =async(url,data)=>{
    const config = {
        headers: {

            Authorization: `Bearer ${localStorage.getItem("token")}`
        }
    }
    try{
        
        const res =await baseUrl.post(url,data , config)
        return res
    }catch(error){
           return error.response
    }
    
}