import { Box, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Textarea, useDisclosure, VStack } from '@chakra-ui/react'
import React from 'react'
import {EditIcon} from '@chakra-ui/icons'
import EditAddressHook from '../../hook/user/edit-address-hook'
import { useTranslation } from 'react-i18next'

const UserEditAddress = ({id}) => {
  const { t, i18n } = useTranslation();

    const { isOpen, onOpen, onClose } = useDisclosure()
    
    const [handelEdit, alias, detalis, phone, onChangeAlias, onChangeDetalis, onChangePhone ] = EditAddressHook(id , isOpen)
    
  return (
    <VStack>
        <Box onClick={onOpen} key={'EditAddress'}><EditIcon/></Box>
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={{base:'xs' , md:'md'}}>
        <ModalOverlay />
        <ModalContent dir={i18n.language === 'ar' ? "rtl" : "ltr" }>
          <ModalHeader>{t("EditAddress")}</ModalHeader>
          <ModalCloseButton left={i18n.language === 'ar' && "var(--chakra-space-3)"}  right={i18n.language === 'ar' ? "" : "var(--chakra-space-3)"} />
          <ModalBody>
            <VStack spacing={'17px'} display='start'>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>{t("place")}</FormLabel>
                <Input 
                value={alias}
                onChange={onChangeAlias}
                type='text'
                placeholder={t("enterPlace")}
                w='100%'
                variant={'unstyled'}
                border={'3px solid rgba(0,0,0,0.1)'}
                p='4.5px 16px'
                fontSize={'17px'}
                fontWeight={'semibold'}
                opacity='.8'
                />
            </FormControl>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>{t("address")}</FormLabel>
                <Textarea
                value={detalis}
                onChange={onChangeDetalis}
                  placeholder={t("enterAddress")}
                  w='100%'
                  variant={'unstyled'}
                  border={'3px solid rgba(0,0,0,0.1)'}
                  p='6px 16px'
                  fontSize={'15px'}
                  fontWeight={'semibold'}
                  opacity='.8'
                  
                  />
            </FormControl>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>{t("phone")}</FormLabel>
                <Input 
                value={phone}
                onChange={onChangePhone}
                type='text'
                placeholder={t("enterPhone")}
                w='100%'
                variant={'unstyled'}
                border={'3px solid rgba(0,0,0,0.1)'}
                p='4.5px 16px'
                fontSize={'17px'}
                fontWeight={'semibold'}
                opacity='.8'
                />
            </FormControl>
              <Button w='100%' onClick={handelEdit} colorScheme={'yellow'}>{t("edit")}</Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      </VStack>
  )
}

export default UserEditAddress