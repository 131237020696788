import { Box, SimpleGrid, VStack } from "@chakra-ui/react";
import React from "react";
import CategoryHeader from "../../components/category/CategoryHeader";
import ProductCard from "../../components/product/ProductCard";
import Filter from "../../components/Utils/Filter";
import SearchResults from "../../components/Utils/SearchResults";
import ViewSearchProductsHook from "../../hook/products/view-search-products-hook";
import CardContainerHook from "../../hook/products/card-container-hook";
import Pagination from "../../components/Utils/Pagination";
import { t } from "i18next";

const ProductShop = () => {
  const [favProd] = CardContainerHook();
  const [items, pagination, onPress, getProduct, results] =
    ViewSearchProductsHook();

  if (pagination) var pageCount = pagination;
  else pageCount = 0;

  return (
    <VStack>
      {/* <CategoryHeader/> */}
      <SearchResults
        onClick={getProduct}
        titleCount={`${results} ${t("productCount")}`}
      />
      <Box
        display="flex"
        justifyContent={"space-between"}
        gap={"30px"}
        w="100%"
        py="20px"
      >
        <Box display={{ base: "none", md: "block" }}>
          <Filter />
        </Box>
        <Box w={{ md: "75%", base: "100%" }}>
          <SimpleGrid
            columns={{ xl: [4], lg: [3], base: [2] }}
            spacing={{ lg: "36px", base: "20px" }}
            w="100%"
          >
            {items ? (
              items.data?.map((item, index) => (
                <ProductCard favProd={favProd} key={index} item={item} />
              ))
            ) : (
              <p>{t("noProductFound")}</p>
            )}
          </SimpleGrid>
        </Box>
      </Box>
      {pageCount > 1 ? (
        <Pagination pageCount={pageCount ? pageCount : 0} onPage={onPress} />
      ) : null}
    </VStack>
  );
};

export default ProductShop;
