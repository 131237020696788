import { Box, Button, FormControl, Heading, Input, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import AddCouponHook from '../../hook/coupon/add-coupon-hook'
import { SmallCloseIcon } from '@chakra-ui/icons'
import { deleteCoupon } from '../../redux/actions/couponAction'
import { useDispatch } from 'react-redux'

const AddCoupon = () => {
    const dispatch = useDispatch()
const [loading, setLoading] = useState(true)

    const [coupnName, couponDate, couponValue, onChangeName, onChangeDate, onChangeValue, onSubmit, coupons] = AddCouponHook()
    const handleDelete = (id)=>{
        dispatch(deleteCoupon(id))
        setLoading(false)
      }


    return (
    <Box>
        <Heading mb='20px'>Add Coupon</Heading>
        <form >
        <FormControl isRequired >
          
        <Input 
        onChange={onChangeName}
        value={coupnName}
        type='text'
        placeholder="name"
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        mb="20px"/>
        </FormControl>
        <FormControl isRequired >
          
        <Input 
        onChange={onChangeDate}
        value={couponDate}
        placeholder="name"
        w='100%'
        type='date'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        mb="20px"/>
        </FormControl>
        <FormControl isRequired >
          
        <Input 
        min={1}
        onChange={onChangeValue}
        value={couponValue}
        type='number'
        placeholder="name"
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        mb="20px"/>
        </FormControl>
        <Button onClick={onSubmit} type='submit' colorScheme={'yellow'}>Save</Button>

        </form>
        <VStack w='100%' spacing={'40px'}  mb='60px'>
    <Text w='100%' fontSize={'28px'} fontWeight={'semibold'} mt='50px' mb='20px'>All Coupons</Text>
        {
      <SimpleGrid columns={{ sm:[3] , base:[2] }} w='100%' spacing={'10px'} >
    {coupons?.length> 0 ?(coupons.map(coupon=>(
      <VStack w="100%" key={coupon._id} alignItems='start' justifyContent={'center'} _hover={{transform:'scale(1.05)'}} transition='ease-out all .5s'>
      <Box 
      
      w="100%"
       alignItems='start' justifyContent={'center'} 
      boxShadow={'0px 0px 7px rgba(0,0,0,0.4)'}
      
      
      backgroundColor={'transparent'} 
      p='15px' 
      display={'flex'} 
      position={'relative'}
      flexDir={'column'}
      mb='5px'
      >
        <Box position={'absolute'} top={'4px'} left={'7px'} onClick={()=>{handleDelete(coupon._id)}} ><SmallCloseIcon cursor={'pointer'} /></Box>
      <Text fontWeight={"bold"} mt="6px">name : {coupon.name} </Text>
      <Text></Text>
      <Text fontWeight={"bold"} mt="6px">expire : {coupon.expire.split("T")[0]} </Text>
      <Text></Text>
      <Text fontWeight={"bold"} mt="6px">discount : {coupon.discount}%</Text>
      <Text></Text>
      </Box> 
      
 
  </VStack>
    ))):(<Text>no data</Text>)}
    </SimpleGrid>
    }
    </VStack>
    </Box>
  )
}

export default AddCoupon