import { Box, Heading, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import AllProductsCard from './AllProductsCard'

const AllProducts = ({ products }) => {

  return (
    <Box>
        <Heading mb='20px'>All Products</Heading>
        <SimpleGrid columns={{ lg:[4] , md :[2] , sm:[2] , base:[2]}} spacing={'20px'} w='100%'>
        {
                    products ? (
                        products?.data?.map((item, index) => <AllProductsCard key={index} item={item} />)
                    ) : <h4> no product data </h4>
                }
       
        </SimpleGrid>
    </Box>
  )
}

export default AllProducts