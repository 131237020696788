import { Box, Flex, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import {AiOutlineHeart} from 'react-icons/ai'
import { AiFillHeart } from "react-icons/ai";
import {GiRoundStar} from 'react-icons/gi'
import { Link } from 'react-router-dom'
import ProductCardHook from '../../hook/products/product-card-hook'

const ProductCard = ({item , favProd}) => {
  const [removeToWishListData, addToWishListData, handelFav, favImg] = ProductCardHook(item, favProd)

  return (
    <VStack  w="100%" boxShadow={'0 0 7px rgba(0,0,0,0.5)'} borderRadius='10px' p='3' _hover={{transform:'translateY(-5px)'}} transition={'ease-out all .3s'}>
      <Box w='100%' fontSize={'30px'}>
        
      
      </Box>
      <VStack spacing={"10px"} w="full" justifyContent={"space-between"} h={"full"}>
      <VStack w={"full"} >
      <Link to={`/product/${item._id}`} style={{display:"flex" , justifyContent:"center" , width:"100%"}} >
      <Image w={'100%'} h={{md:'200px' , base:"150px"}} borderRadius={"5px"} src={item.imageCover}/>
      </Link>
      <Text w='100%' alignItems={'start'} opacity={'.8'} fontSize={'17px'} fontWeight={'semibold'}>{item.title}</Text>
      </VStack>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={"center"} w='100%'>
      {
          !favImg ? (<AiOutlineHeart  onClick={handelFav} style={{color:'gray' , width:"22px" , height:"22px"}} />) : (<AiFillHeart  onClick={handelFav} style={{color:'gray' , width:"22px" , height:"22px"}} />)
        }
        <Text fontWeight={'bold'}>{item.price}$</Text>
      </Box>
      </VStack>
    </VStack>
  )
}

export default ProductCard