import React, { useState, useEffect } from "react";
import { getOneCategory } from "../../redux/actions/subcategoryAction";
import {
  getOneProductAr,
  getOneProductEn,
} from "../../redux/actions/productsAction";
import { useSelector, useDispatch } from "react-redux";
import { getAllCategory } from "../../redux/actions/cateogryAction";
import { getAllBrand } from "./../../redux/actions/brandAction";
import { updateProducts } from "./../../redux/actions/productsAction";
import baseUrl from "./../../api/baseUrl";
import { useToast } from "@chakra-ui/react";
import avatar from "../../images/avatar-removebg-preview.png";

const AdminEditProductsHook = (id) => {
  const toast = useToast();
  const dispatch = useDispatch();
  useEffect(() => {
    const run = async () => {
      await dispatch(getOneProductAr(id));
      await dispatch(getOneProductEn(id));
      await dispatch(getAllCategory());
      await dispatch(getAllBrand());
    };
    run();
  }, [id]);

  //get one product details
  const itemEn = useSelector((state) => state.allproducts.onProductsEn);
  const itemAr = useSelector((state) => state.allproducts.onProductsAr);
  //get last catgeory state from redux

  const category = useSelector((state) => state.allCategory.category);
  //get last brand state from redux
  const brand = useSelector((state) => state.allBrand.brand);

  //get last sub cat state from redux
  const subCat = useSelector((state) => state.subCategory.subcategory);

  const onSelect = (selectedList) => {
    setSeletedSubID(selectedList);
  };
  const onRemove = (selectedList) => {
    setSeletedSubID(selectedList);
  };

  const [options, setOptions] = useState([]);

  //values images products
  const [images, setImages] = useState([]);
  //values state
  const [prodName, setProdName] = useState("");
  const [prodDescription, setProdDescription] = useState("");
  const [priceBefore, setPriceBefore] = useState("السعر قبل الخصم");
  const [priceAftr, setPriceAftr] = useState("السعر بعد الخصم");
  const [qty, setQty] = useState("الكمية المتاحة");
  const [CatID, setCatID] = useState("0");
  const [BrandID, SetBrandID] = useState("0");
  const [subCatID, setSubCatID] = useState([]);
  const [seletedSubID, setSeletedSubID] = useState([]);
  const [inputOptions, setInputOptions] = useState("");

  const [loading, setLoading] = useState(true);
  const [optionSize, setOptionSize] = useState([]);
  const [prodDescriptionEn, setProdDescriptionEn] = useState("");
  const [prodNameEn, setProdNameEn] = useState("");

  const [imageCover, setImageCover] = useState(avatar);

  const product = useSelector((state) => state.allproducts.updateProducts);

  useEffect(() => {
    if (itemEn?.data?.data && itemEn != undefined) {
      setOptionSize(itemEn?.data?.data?.options);
      setImageCover(itemEn?.data?.data?.imageCover);

      setProdNameEn(itemEn.data.data.title);

      setProdDescriptionEn(itemEn.data.data.description);
      setPriceBefore(itemEn.data.data.price);
      setQty(itemEn.data.data.quantity);
      setCatID(itemEn.data.data.category);
      SetBrandID(itemEn.data.data.brand);
      setColors(itemEn.data.data.availableColors);
    }
  }, [itemEn]);

  const [selectedFile, setSelectedFile] = useState(null);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageCover(URL.createObjectURL(event.target.files[0]));
      setSelectedFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (itemAr?.data?.data && itemAr != undefined) {
      setProdName(itemAr?.data?.data?.title);

      setProdDescription(itemAr?.data?.data?.description);
    }
  }, [itemAr]);

  //to change name state
  const onChangeProdName = (event) => {
    event.persist();
    setProdName(event.target.value);
  };

  const onChangeProdNameEn = (event) => {
    event.persist();
    setProdNameEn(event.target.value);
  };
  //to change name state
  const onChangeDesName = (event) => {
    event.persist();
    setProdDescription(event.target.value);
  };

  const onChangeDesNameEn = (event) => {
    event.persist();
    setProdDescriptionEn(event.target.value);
  };
  //to change name state
  const onChangePriceBefor = (event) => {
    event.persist();
    setPriceBefore(event.target.value);
  };
  //to change name state
  const onChangePriceAfter = (event) => {
    event.persist();
    setPriceAftr(event.target.value);
  }; //to change name state
  const onChangeQty = (event) => {
    event.persist();
    setQty(event.target.value);
  };
  const onChangeColor = (event) => {
    event.persist();
    setShowColor(!showColor);
  };

  //to show hide color picker
  const [showColor, setShowColor] = useState(false);
  //to store all pick color
  const [colors, setColors] = useState([]);
  //when choose new color
  const handelChangeComplete = (color) => {
    setColors([...colors, color.hex]);
    setShowColor(!showColor);
  };
  const removeColor = (color) => {
    const newColor = colors.filter((e) => e !== color);
    setColors(newColor);
  };

  //when selet category store id
  const onSeletCategory = async (e) => {
    setCatID(e.target.value);
  };
  useEffect(() => {
    if (CatID != 0) {
      const run = async () => {
        await dispatch(getOneCategory(CatID));
      };
      run();
    }
  }, [CatID]);

  useEffect(() => {
    if (subCat) {
      setOptions(subCat.data);
    }
  }, [subCat]);

  //when selet brand store id
  const onSeletBrand = (e) => {
    SetBrandID(e.target.value);
  };

  //to convert base 64 to file
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  //convert url to file
  const convertURLtoFile = async (url) => {
    const response = await fetch(url, { mode: "cors" });
    const data = await response.blob();
    const ext = url.split(".").pop();
    const filename = url.split("/").pop(); // Extract filename from URL
    const metadata = { type: `image/${ext}` };
    return new File([data], filename, metadata); // Use filename instead of Math.random()
  };

  //to save data
  const handelSubmit = async (e) => {
    e.preventDefault();
    if (
      CatID === "0" ||
      prodName === "" ||
      prodDescription === "" ||
      priceBefore <= 0
    ) {
      toast({
        title: "please fill full fields",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("ar[title]", prodName);
    formData.append("ar[description]", prodDescription);
    formData.append("en[title]", prodNameEn);
    formData.append("en[description]", prodDescriptionEn);

    formData.append("quantity", qty);
    formData.append("price", priceBefore);

    formData.append("category", CatID);
    formData.append("brand", BrandID);

    let imgCover;
    if (imageCover.startsWith("http")) {
      imgCover = await convertURLtoFile(imageCover);
      formData.append("imageCover", imgCover);
    } else {
      formData.append("imageCover", selectedFile);
    }

    colors.forEach((color) => formData.append("availableColors", color));
    seletedSubID.forEach((item) => formData.append("subcategory", item._id));
    optionSize.map((op) => formData.append("options", JSON.stringify(op)));

    setLoading(true);
    await dispatch(updateProducts(id, formData));
    setLoading(false);
  };

  //get create meesage

  useEffect(() => {
    if (loading === false) {
      //setCatID(0)

      if (product) {
        if (product.status === 200) {
          toast({
            title: "product edited successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          toast({
            title: "  there is error",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    }
  }, [loading]);

  return [
    CatID,
    BrandID,
    onChangeDesName,
    onChangeQty,
    onChangeColor,
    onChangePriceAfter,
    onChangePriceBefor,
    onChangeProdName,
    showColor,
    category,
    brand,
    priceAftr,
    images,
    setImages,
    onSelect,
    onRemove,
    options,
    handelChangeComplete,
    removeColor,
    onSeletCategory,
    handelSubmit,
    onSeletBrand,
    colors,
    priceBefore,
    qty,
    prodDescription,
    prodName,
    prodNameEn,
    onChangeProdNameEn,
    prodDescriptionEn,
    onChangeDesNameEn,
    inputOptions,
    setInputOptions,
    optionSize,
    setOptionSize,
    imageCover,
    onImageChange,
  ];
};

export default AdminEditProductsHook;
