import { VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Container } from '@chakra-ui/react'
import Slider from '../../components/Home/Slider'
import HomeCategory from '../../components/Home/HomeCategory'
import Product from '../../components/product/Product'
import DiscountSection from '../../components/Home/DiscountSection'
import Brand from '../../components/Brand/Brand'
import ViewHomeProductsHook from '../../hook/products/view-home-products-hook'
import { useTranslation } from 'react-i18next'

const Home = () => {
  const { t, i18n } = useTranslation();
  
const [item] = ViewHomeProductsHook()

  return (
    <VStack>
        <Container maxW={{xl:'container.xl' , lg:'container.lg',  md:'container.md' , sm:'container.sm' , base:'400px'}}>
            <Slider/>
            <Product items={item} title={t('mostseller')} btntitle={t("more")}/> 
            <DiscountSection/>
            <Product items={item} title={t("mostrated")} btntitle={t("more")}/>
            <HomeCategory/>
            
            <Brand title={t("brand")} btntitle={t("more")}/>
        </Container>
    </VStack>
  )
}

export default Home