import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addProductToCart } from "./../../redux/actions/cartAction";
import { useToast } from "@chakra-ui/react";

const AddToCartHook = (prdID, item, type) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const [indexColor, setIndexColor] = useState("");
  const [colorText, setColorText] = useState("");
  const [indexOptions, setIndexOptions] = useState("");
  const [options, setOptions] = useState("");
  const [loading, setLoading] = useState(true);
  const colorClick = (index, color) => {
    setIndexColor(index);
    setColorText(color);
  };
  const optionClick = (index, color) => {
    setIndexOptions(index);
    setOptions(color);
  };

  //add product to cart
  const addToCartHandel = async () => {
    if (item.availableColors.length >= 1) {
      if (colorText === "") {
        toast({
          title: " choose color of product ",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    } else {
      setColorText("");
    }
    if (item.options.length >= 1) {
      if (options === "") {
        toast({
          title: " choose option of product ",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    } else {
      setOptions("");
    }
    setLoading(true);
    await dispatch(
      addProductToCart({
        productId: prdID,
        color: colorText,
        options,
        fakeUser: localStorage.getItem("fakeUser"),
      })
    );
    setLoading(false);
  };

  const res = useSelector((state) => state.cartReducer.addToCart);

  useEffect(() => {
    if (loading === false) {
      if (res && res.status === 200 && type === false) {
        toast({
          title: " the product add to cart successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 500);
      } else if (res && res.status === 200 && type === true) {
        toast({
          title: " the product add to cart successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        window.location.href = "/cart";
      } else if (res && res.status == 400) {
        toast({
          title: "not enough item in storage",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "   please login ",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [loading]);

  return [colorClick, indexColor, addToCartHandel, indexOptions, optionClick];
};

export default AddToCartHook;
