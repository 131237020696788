import baseUrl from "../api/baseUrl";

export const useGetData = async (url, params) => {
  const res = await baseUrl.get(url, params);
  return res;
};

export const useGetDataToken = async (url, parmas) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  try {
    const res = await baseUrl.get(url, config);
    return res.data;
  } catch (error) {
    return error.response;
  }
};
