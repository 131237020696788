import { Heading, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getProductWishList } from '../../redux/actions/wishListAction';
import Product from '../product/Product';
import { useTranslation } from 'react-i18next';

function UserFavorite() {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    useEffect(() => {
        const get = async () => {
            setLoading(true)
            await dispatch(getProductWishList())
            setLoading(false)
        }
        get()
    }, [])

    const res = useSelector(state => state.addToWishListReducer.allWishList)
    useEffect(() => {
        if (loading === false) {
            if (res)
                setItems(res.data)
        }
    }, [loading])
  return (
    <VStack w='100%' display={'start'} spacing='20px'>
        <Heading>{t("Favorite")}</Heading>
        {
                    items.length <= 0 ? (<h6>{t("whishlist")} </h6>) : <Product items={items} title=' ' btntitle={''}/> 
                }
    </VStack>
  )
}

export default UserFavorite