import { Box, Flex } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import AdminSideBar from '../../components/admin/AdminSideBar'
import AddCoupon from '../../components/admin/AddCoupon';

const AdminCoupon = () => {
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
      
        if (!user || user.role !== "admin") {
          window.location.href = '/'; // Redirect to the home page
        }
      }, []);
  return (
    <Flex dir='ltr' w='100%' flexDir={{base:'column' ,md:'row'}} justifyContent={'space-between'} mb='40px'>
        <Box w={{md:'25%' , base:'100%'}}>
        <AdminSideBar/>
        </Box>
        <Box w={{md:'70%' , base:'100%'}}>
            <AddCoupon/>
        </Box>
    </Flex>
  )
}

export default AdminCoupon