import { Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react'
import React from 'react'
import Login from '../../components/auth/Login'
import SignUp from '../../components/auth/SignUp'
import { t } from 'i18next'

const LoginPage = () => {
  return (
    <VStack display={'flex'} justifyContent='center'  mt='50px'>
      <Tabs isFitted variant='soft-rounded' colorScheme='yellow' w={{xl:"35%",lg:'50%' , base:'100%'}}>
        <TabList mb='1em'>
          <Tab>{t("login")}</Tab>
          <Tab>{t("SignUp")}</Tab>
        </TabList>
        <TabPanels>
        <TabPanel>
          <Login/>
        </TabPanel>
        <TabPanel>
          <SignUp/>
        </TabPanel>
      </TabPanels>
</Tabs>
    </VStack>
  )
}

export default LoginPage