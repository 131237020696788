import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Button, FormControl, FormLabel, Input, InputGroup, InputLeftElement, InputRightElement, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import RegisterHook from '../../hook/auth/register-hook'
import { useTranslation } from 'react-i18next'

const SignUp = () => {
    const { t, i18n } = useTranslation();

    const [show, setShow] = useState(false)
    const [name, email, phone, password, confirmPassword, loading, onChangeName, onChangeEmail, onChangePhone, onChangePassword, onChangeConfirmPassword, OnSubmit] = RegisterHook();
  return (
    <form style={{width:"100%"}}>
    <VStack>
        <FormControl isRequired>
        <FormLabel color={'yellow.500'}>{t("userName")}</FormLabel>
        <Input 
        value={name}
        onChange={onChangeName}
        type='text' 
        placeholder={t("enterName")}
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        />
        </FormControl>
        <FormControl isRequired>
        <FormLabel color={'yellow.500'}>{t("email")}</FormLabel>
        <Input 
        value={email}
        onChange={onChangeEmail}
        type='email' 
        placeholder={t("enterEmail")}
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        />
        </FormControl>
        <FormControl isRequired>
        <FormLabel color={'yellow.500'}> {t("phone")}</FormLabel>
        <Input 
        value={phone}
        onChange={onChangePhone}
        type='text' 
        placeholder={t("enterPhone")}
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        />
        </FormControl>
        <FormControl isRequired>
        <FormLabel color={'yellow.500'}>{t("password")}</FormLabel>
        <InputGroup>
        <Input
        value={password}
        onChange={onChangePassword}
        type={show?'text' : "password"} 
        placeholder={t("enterPassword")}
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        />
        {
          i18n.language ==="ar" && (<InputLeftElement>
            <Button onClick={()=>{setShow(!show)}}>{show?<ViewOffIcon/>: <ViewIcon/>}</Button>
    
            </InputLeftElement>)
        }

{
          i18n.language ==="en" && (<InputRightElement>
            <Button onClick={()=>{setShow(!show)}}>{show?<ViewOffIcon/>: <ViewIcon/>}</Button>
        </InputRightElement>)
        }
        
            
        </InputGroup>
        </FormControl>
        <FormControl isRequired>
        <FormLabel color={'yellow.500'}>{t("confirmPassword")}</FormLabel>
      
        <Input 
        value={confirmPassword}
        onChange={onChangeConfirmPassword}
        type={show?'text' : "password"} 
        placeholder={t("confirmPassword")}
        w='100%'
        variant={'unstyled'}
        border={'3px solid rgba(0,0,0,0.1)'}
        p='4.5px 16px'
        fontSize={'17px'}
        fontWeight={'semibold'}
        opacity='.8'
        mb="20px"
        />
          
    
        </FormControl>
        
        <Button w={'100%'} type='submit' colorScheme={'yellow'} onClick={OnSubmit} > {t("SignUp")}</Button>
    </VStack>
    </form>
  )
}

export default SignUp