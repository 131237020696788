import React, { useEffect, useState } from 'react'
import avatar from '../../images/avatar-removebg-preview.png'
import { addSlider, deleteSlider, getAllSlider } from '../../redux/actions/sliderActions'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, FormControl, Heading, Image, Input, SimpleGrid, Text, VStack, useToast } from '@chakra-ui/react'
import { SmallCloseIcon } from '@chakra-ui/icons'

const AddSlider = () => {
    const toast = useToast()
    const [img, setImg] = useState(avatar)
  const [loading, setLoading] = useState(true)
  const [loadingD, setLoadingD] = useState(true)
  const [selectedFile, setSelectedFile] = useState(null)


  const res = useSelector(state => state.sliderReducer.slider)
  const createRes = useSelector(state => state.sliderReducer.createSLider)
  const deleteRes = useSelector(state => state.sliderReducer.deleteSlider)
  const handleDelete = (id)=>{ 
    dispatch(deleteSlider(id))
    setLoadingD(false)
 }




  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllSlider())
    setLoadingD(true)
  }, [dispatch ,createRes , loadingD])

  const onImageChange = (event) =>{
    if(event.target.files && event.target.files[0]){
      setImg( URL.createObjectURL(event.target.files[0]))
      setSelectedFile(event.target.files[0])
    }
}
const handleSubmit = async(e)=>{
    e.preventDefault()
    const formData =new FormData()
    formData.append('image',selectedFile)
    if(img === avatar){
        toast({
            title:'please add image ',
            status:'error',
            duration:3000,
            isClosable:true 
            })
            
    }else{
        await dispatch(addSlider(formData ))
        setLoading(false)
    }
    
    
  }

useEffect(() => {
    if(loading===false){
    
        if(createRes.status === 201){
          setImg(avatar)
       
          setSelectedFile(null)
          setLoading(true)
          toast({
            title:'slider added successfully',
            status:'success',
            duration:3000,
            isClosable:true 
            })
            
        
        }else if(createRes.response.status === 400){
          toast({
            title:'  fill fields',
            status:'error',
            duration:3000,
            isClosable:true
          })
        }else{
            toast({
            title:"error",
            status:'error',
            duration:3000,
            isClosable:true
          })
        }
      }
    
  }, [res,loading , toast , selectedFile])

  return (
    <Box>
        <Heading
         mb='20px'>Add New Slider</Heading>
        <form >
        <FormControl isRequired>
        
        <label htmlFor="upload-photo">
        <Image mb='30px' src={img} cursor={'pointer'} w={{md:'260px', base:"210"}}   h={{md:'200px' ,base:"150px"}}/>
        </label>
        <Input w='0' variant={'unstyled'} type="file" name='photo' onChange={onImageChange} id='upload-photo'/>
       
        </FormControl>
        <FormControl isRequired>
          
        
        </FormControl>
        <Button onClick={handleSubmit} type='submit' colorScheme={'yellow'}>Save</Button>
        </form>

    <VStack w='100%' spacing={'40px'}  mb='60px'>
    <Text w='100%' fontSize={'28px'} fontWeight={'semibold'} mt='50px' mb='20px'>All Slider</Text>
    {
      <SimpleGrid columns={{xl:[6] ,lg:[5] , md:[4] , sm:[3] , base:[2] }} w='100%' spacing={'60px'} >
    {res?.data?.length> 0 ?(res.data.map(slider=>(
      <VStack key={slider._id} alignItems='center' justifyContent={'center'} _hover={{transform:'scale(1.1)'}} transition='ease-out all .5s'>
      <Box 
       alignItems='center' justifyContent={'center'} 
      boxShadow={'0px 0px 7px rgba(0,0,0,0.4)'}
      w={{base:'120px'}}
      h={{base:'120px'}}
      backgroundColor={'transparent'} 
      p='15px' 
      display={'flex'} 
      position={'relative'}
      flexDir={'column'}
      mb='5px'
      >
        <Box position={'absolute'} top={'4px'} left={'7px'} ><SmallCloseIcon cursor={'pointer'} onClick={()=>handleDelete(slider._id)}/></Box>
      <Image src={slider.image} w={'80px'} h='80px'/>
      </Box>
      
  </VStack>
    ))):(<Text>no data</Text>)}
    </SimpleGrid>
    }
  

    </VStack>

    </Box>
  )
}

export default AddSlider