import { base } from "../../api/baseTextUrl"
import useDeleteData from "../../hooks/useDeleteData"
import { useGetData } from "../../hooks/useGetData"
import { useInsertDataWithImage } from "../../hooks/useInsertData"
import { useInUpdateDataWithImage } from "../../hooks/useUpdateData"
import { ADD_BRAND, DELETE_BRAND, GET_ALL_BRAND, GET_ERROR, GET_ONE_BRAND, GET_ONE_BRAND_AR, GET_ONE_BRAND_EN, UPDATE_BRAND } from "../types"
import axios from "axios"


export const getAllBrand = (limit,page)=> async(dispatch)=>{
    try{
        const res = await useGetData(`/api/v1/brands?limit=${limit}&page=${page}`)
        dispatch({
            type: GET_ALL_BRAND,
            payload:res?.data,
        })
    }catch(error){
        dispatch({
            type:GET_ERROR,
            payload:"Error : " + error?.response?.data
        })
    }
} 

//get one Brand
export const getOneBrand = (id) => async (dispatch) => {
    try {
        const response = await useGetData(`/api/v1/brands/${id}`);

        dispatch({
            type: GET_ONE_BRAND,
            payload: response?.data,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
}

export const deleteBrand = (id) => async (dispatch) => {
    try {
        const response = await useDeleteData(`/api/v1/brands/${id}`);

        dispatch({
            type: DELETE_BRAND,
            payload: response,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
}

export const addBrand = (data)=> async(dispatch)=>{
    
    try {
        const res = await useInsertDataWithImage('/api/v1/brands' , data)
   
        dispatch({
            type :ADD_BRAND,
            payload:res,
        })
    } catch (error) {
        dispatch({
            type: GET_ERROR,
            payload: "Error : " + error?.response?.data
        })
    }
}


export const updateBrand = (id ,data) => async(dispatch)=>{
    try {
        const response = await useInUpdateDataWithImage(`/api/v1/brands/${id}` , data);

        dispatch({
            type: UPDATE_BRAND,
            payload: response,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
    
}


export const getOneBrandAr = (id) => async(dispatch)=>{
    try {
        const response = await axios.get(`${base}/api/v1/brands/${id}?lang=ar`);

        dispatch({
            type: GET_ONE_BRAND_AR,
            payload: response,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
    
}

export const getOneBrandEn = (id) => async(dispatch)=>{
    try {
        const response = await axios.get(`${base}/api/v1/brands/${id}?lang=en`);

        dispatch({
            type: GET_ONE_BRAND_EN,
            payload: response,
        })

    } catch (e) {
        dispatch({
            type: GET_ERROR,
            payload: "Error " + e,
        })
    }
    
}