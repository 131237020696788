import axios from "axios";

const baseUrl = axios.create({
  baseURL: "https://backend.finolb.com/",
});
baseUrl.interceptors.request.use(function (config) {
  // Modify config object before sending the request

  // Set the language parameter as part of the URL query string
  const lang = localStorage.getItem("i18nextLng");
  if (lang) {
    // Append language parameter to the URL
    config.url +=
      (config.url.includes("?") ? "&" : "?") +
      "lang=" +
      encodeURIComponent(lang);
  }

  return config;
});

export default baseUrl;
