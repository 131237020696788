import { PhoneIcon } from '@chakra-ui/icons'
import { Box, Container, Flex, Heading, Icon, Image, SimpleGrid, Text } from '@chakra-ui/react'
import { IoLocationSharp } from "react-icons/io5";
import React from 'react'

import { Link } from 'react-router-dom'
import Passionlogo from "../../images/Passionlogo.png"
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookSquare } from "react-icons/fa";
import { CgMail } from "react-icons/cg";

const Footer = () => {
  return (
    <Box width={"100%"} py={"3.5rem"} color={"white"} bgColor={"#1a202c"} dir='ltr'>
        <Container  maxW={{xl:'container.xl' , lg:'container.lg',  md:'container.md' , sm:'container.sm' , base:'400px'}} >

       <SimpleGrid columns={{lg: [4] , md:[3] , base:[1]}} w="100%" spacing={{lg:"10px" , base:"50px"}}>
       <Box>
            <Heading fontSize={"xl"} mb={"18px"}>SOCIAL</Heading>
                <Flex flexDir={"column"} gap="4px" >

                <a href='https://www.instagram.com/finova.online?igsh=MTdvbGUwMWF0Y29pdQ=='>
                <Flex alignItems={"center"} gap="3px"> <AiFillInstagram /> <Text> Finova</Text>   </Flex>
                    
                    </a>
                    <a href='https://www.instagram.com/fino.wear?igsh=M2FtYnBjZ2o1dXc='>
                <Flex alignItems={"center"} gap="3px"> <AiFillInstagram /> <Text> Finowear</Text>   </Flex>
                    
                    </a>
                    <a href='https://www.facebook.com/finovaonline?mibextid=LQQJ4d'>
                <Flex alignItems={"center"} gap="3px"> <FaFacebookSquare /><Text> Finova</Text>   </Flex>
                    
                    </a>
                    <a href='https://www.facebook.com/finowear.online?mibextid=LQQJ4d'>
                <Flex alignItems={"center"} gap="3px"><FaFacebookSquare /><Text> Finowear</Text>   </Flex>
                    
                    </a>
                <Flex alignItems={"center"} gap="3px"><CgMail /><Text> Finova.online@gmail.com</Text>   </Flex>

                
                </Flex>
            </Box>
            <Box >
                <Heading fontSize={"xl"} mb={"18px"}>LEGAL</Heading>
                <Flex flexDir={"column"} gap="4px" >

                <Link to={"/shipping-policy"}><Text>Shipping Policy</Text> </Link>
                
                </Flex>
            </Box>
            <Box>
            <Heading fontSize={"xl"} mb={"18px"}>SUPPORT</Heading>
                <Flex flexDir={"column"} gap="4px" >

                <Flex alignItems={"center"} gap={"3px"}> <Icon as={PhoneIcon}/> <Text>+96181120339 - Omar sheikhdib</Text> </Flex>
                <Flex alignItems={"center"} gap="3px"> <Icon as={PhoneIcon}/> <Text>+96176472296 - haysam mereebi</Text>   </Flex>
                </Flex>
            </Box>
            <Box>
            <Heading fontSize={"xl"} mb={"18px"}>LOCATION</Heading>
                <Flex flexDir={"column"} gap="4px" >

                <a href='https://maps.app.goo.gl/oo4XstXdJhzHNWAg9?g_st=iw'>
                <Flex alignItems={"center"} gap="3px"> <IoLocationSharp /> <Text>Tripoli lebanon</Text>   </Flex>
                    
                    </a>
                
                </Flex>
            </Box>
            
       </SimpleGrid>
       <Flex w="100%" alignItems={"end"} py={"4px"}  justifyContent={'center'} mt="30px" gap="5px"> v1 created by &copy; <Image w="70px" h="40px" src={Passionlogo}/></Flex>
        </Container>
    </Box>
  )
}

export default Footer