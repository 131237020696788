import { Box, Flex } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import AdminSideBar from '../../components/admin/AdminSideBar'
import AllProducts from '../../components/admin/AllProducts'
import ViewProductAdminHook from '../../hook/admin/view-product-admin-hook'
import Pagination from '../../components/Utils/Pagination'

const AdminAllProducts = () => {

  const [items, pagination,onPress] = ViewProductAdminHook();
  if (pagination)
        var pageCount = pagination;
    else
        pageCount = 0;

useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
  
    if (!user || user.role !== "admin") {
      window.location.href = '/'; // Redirect to the home page
    }
  }, []);
  return (
    <Flex dir='ltr' w='100%' flexDir={{base:'column' ,md:'row'}} justifyContent={'space-between'} mb='40px'>
        <Box w={{md:'25%' , base:'100%'}}>
                <AdminSideBar/>
        </Box>
        <Box w={{md:'70%' ,base:'100%'}}>
            <AllProducts products={items} />
            {
                        pageCount > 1 ? (<Pagination pageCount={pageCount} onPage={onPress} />) : null
                    }
        </Box>
    </Flex>
  )
}

export default AdminAllProducts