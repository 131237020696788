import {
  Text,
  Box,
  Flex,
  Circle,
  HStack,
  Button,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { GiRoundStar } from "react-icons/gi";
import ViewProductsDetalisHook from "../../hook/products/view-products-detalis-hook";
import { useParams } from "react-router-dom";
import AddToCartHook from "../../hook/cart/add-to-cart-hook";
import { useTranslation } from "react-i18next";
const ProductText = () => {
  const [type, setType] = useState(false);

  const { t, i18n } = useTranslation();

  const { id } = useParams();
  const [item, cat, brand, prod, imageCover] = ViewProductsDetalisHook(id);

  const [colorClick, indexColor, addToCartHandel, indexOptions, optionClick] =
    AddToCartHook(id, item, type);
  return (
    <Box
      display={"flex"}
      flexDir={"column"}
      h={{ lg: "480px" }}
      w="100%"
      p={{ sm: "3", base: "0" }}
      gap={"12px"}
    >
      <Text
        fontSize={{ lg: "30px", md: "26px", sm: "25px", base: "23px" }}
        fontWeight="semibold"
        opacity={".8"}
      >
        {item.title}
      </Text>

      <Flex
        fontSize={"20px"}
        fontWeight={"bold"}
        alignItems={"center"}
        color={"yellow.400"}
      >
        {item.ratingsAverage ? item.ratingsAverage : "0"}
        <GiRoundStar />
      </Flex>
      <Flex flexDir={"column"} gap="14px">
        <Flex>
          <Text
            opacity={".6"}
            fontSize={"20px"}
            fontWeight="semibold"
            mr={"10px"}
          >
            {t("brandDetail")} :{" "}
          </Text>
          <Text fontSize={"22px"} fontWeight="bold">
            {brand?.name}
          </Text>
        </Flex>
        <VStack spacing={"10px"} w="100%" display="start">
          <HStack spacing={"10px"}>
            {item.availableColors
              ? item.availableColors.map((color, index) => {
                  return (
                    <Circle
                      key={index}
                      w="40px"
                      h="40px"
                      boxShadow={"0 0 7px rgba(0,0,0,0.8)"}
                      onClick={() => colorClick(index, color)}
                      bgColor={color}
                      border={indexColor === index ? "3px solid black" : "none"}
                    ></Circle>
                  );
                })
              : null}
          </HStack>
          <Flex alignItems={"start"} gap="6px" flexWrap={"wrap"}>
            {item?.options
              ? item.options.map((option, index) => {
                  return (
                    <Box
                      key={index}
                      opacity={".8"}
                      rounded={"md"}
                      fontSize={"18px"}
                      cursor={"pointer"}
                      border={"solid 1px black"}
                      _hover={{ bgColor: "black", color: "white" }}
                      bgColor={indexOptions === index ? "black" : "white"}
                      color={indexOptions === index ? "white" : "black"}
                      px={"15px"}
                      onClick={() => optionClick(index, option.name)}
                    >
                      {option.name}
                    </Box>
                  );
                })
              : null}
          </Flex>
        </VStack>
      </Flex>

      <Box lineHeight={"25px"} mt="30px">
        <Text mb="10px" fontSize={"25px"} fontWeight="bold">
          {t("description")}
        </Text>
        <Text opacity=".7" fontWeight={"medium"}>
          {item.description}
        </Text>
      </Box>
      <Box fontWeight="600" variant={"unstyled"} fontSize={"20px"}>
        Price : {item.price}$
      </Box>
      <HStack mt="25px">
        <Button
          onClick={() => {
            setType(false);
            addToCartHandel();
          }}
        >
          {t("addToCart")}
        </Button>
        <Button
          onClick={() => {
            setType(true);
            addToCartHandel();
          }}
        >
          {t("buyNow")}
        </Button>
      </HStack>
    </Box>
  );
};

export default ProductText;
