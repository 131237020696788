import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay,  Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

function UserSideBarSmallScreen({isOpen,onClose}) {
  const { t, i18n } = useTranslation();

  return (
    <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent dir={i18n.language === 'ar' ? "rtl" : "ltr" }>
          <DrawerCloseButton left={i18n.language === 'ar' && "var(--chakra-space-3)"}  right={i18n.language === 'ar' ? "" : "var(--chakra-space-3)"} />
          <DrawerHeader color={'yellow.400'}>{t("Settings")}</DrawerHeader>
          <DrawerBody>
          <VStack>
        <a href='/user/allorders' className='link' style={{width:'100%'}} >
            <Text  borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>{t("OrderManagement")}</Text>
        </a>
        <a href='/user/favoriteproducts' className='link' style={{width:'100%'}} >
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>{t("FavoriteProducts")} </Text>
        </a>
        <a href='/user/address' className='link' style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4'  fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>{t("AddAddress")} </Text>
        </a>
        <a href='/user/profile' className='link'  style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>{t("Profile")}</Text>
        </a>
    </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
  )
}

export default UserSideBarSmallScreen