import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearAllCartItem,
  deleteCartItem,
  updateCartItem,
} from "./../../redux/actions/cartAction";
import { useToast } from "@chakra-ui/react";

const DeleteCartHook = (item) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [itemCount, setItemCount] = useState(item?.count);
  const fakeUser = localStorage.getItem("fakeUser");

  const handelDeleteCart = async () => {
    setLoading(true);
    await dispatch(clearAllCartItem());
    setLoading(false);
  };
  const onChangeCount = (e) => {
    setItemCount(e.target.value);
  };
  useEffect(() => {
    if (item) setItemCount(item.count);
  }, [item]);
  const res = useSelector((state) => state.cartReducer.clearCart);
  useEffect(() => {
    if (loading === false) {
      if (res === "") {
        toast({
          title: "deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      } else {
        toast({
          title: "there is an error",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [loading]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //const dispatch = useDispatch();

  const handelDeleteItem = async () => {
    await dispatch(deleteCartItem(item?._id, { fakeUser }));
    setShow(false);
    window.location.reload(false);
  };
  useEffect(() => {
    const get = async () => {
      if (item) {
        await dispatch(
          updateCartItem(item?._id, {
            count: itemCount,
            fakeUser,
          })
        );
      }
    };
    get();
  }, [itemCount]);

  const resUp = useSelector((state) => state.cartReducer.updateItem);

  useEffect(() => {
    if (resUp.status == 400) {
      toast({
        title: "not enough items in storage",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [resUp]);

  return [
    handelDeleteCart,
    show,
    handleClose,
    handleShow,
    handelDeleteItem,
    itemCount,
    onChangeCount,
  ];
};

export default DeleteCartHook;
