import {   Button, FormControl, FormLabel, Heading, Input, InputGroup, InputLeftElement, InputRightElement, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import UserEditProfile from './UserEditProfile'
import ProfileHook from '../../hook/user/profile-hook'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'


const UserProfil = () => {
  const { t, i18n } = useTranslation();

    const [shows, setShows] = useState(false)
    const [user, show, handleClose, handleShow, handelSubmit, name, email, phone, onChangeName, onChangeEmail, onChangePhone, changePassword, oldPassword, newPassword, confirmNewPassword, onChangeOldPass, onChangeNewPass, onChangeConfirmPass] = ProfileHook()

  return (
    <VStack display={'start'} w='100%' spacing={'20px'}>
        <Heading>{t("Profile")}</Heading>
        <VStack spacing={'12px'} display={'start'} borderRadius={'5px'} w='100%' boxShadow={'0 0 5px rgba(0,0,0,.5)'} p='14px'>
            <VStack spacing={'5px'} display='start'>
                <Text fontWeight={'700'}>{t("userName")}</Text>
                <Text fontSize={'15px'} fontWeight='500' opacity={'.8'}>{user.name}</Text>
            </VStack>
            <VStack spacing={'5px'} display='start'>
                <Text fontWeight={'700'}>{t("phone")}</Text>
                <Text fontSize={'15px'} fontWeight='500' opacity={'.8'}>{user.phone}</Text>
            </VStack>
            <VStack spacing={'5px'} display='start'>
                <Text fontWeight={'700'}>{t("email")}</Text>
                <Text fontSize={'15px'} fontWeight='500' opacity={'.8'}>{user.email}</Text>
            </VStack>
            <UserEditProfile name={name} onChangeName={onChangeName} phone={phone} onChangePhone={onChangePhone} email={email} onChangeEmail={onChangeEmail} handelSubmit={handelSubmit}/>
        </VStack>
        <VStack spacing={'22px'} display={'start'} borderRadius={'5px'} w='100%' boxShadow={'0 0 5px rgba(0,0,0,.5)'} p='14px'>
            <Text fontWeight={'600'} fontSize={'17px'}>{t("ChangeYourPassword")}</Text>
            <VStack>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>{t("OldPassword")}</FormLabel>
                <Input 
                value={oldPassword}
                onChange={onChangeOldPass}
                type='text'
                placeholder={t("EnterYourOldPassword")}
                w='100%'
                variant={'unstyled'}
                border={'3px solid rgba(0,0,0,0.1)'}
                p='4.5px 16px'
                fontSize={'17px'}
                fontWeight={'semibold'}
                opacity='.8'
                />
            </FormControl>
            <FormControl isRequired>
        <FormLabel color={'yellow.500'}>{t("password")}</FormLabel>
        <InputGroup>
        <Input 
        value={newPassword}
                onChange={onChangeNewPass}
        type={shows?'text' : "password"} 
              
                placeholder={t("enterPassword")}
                w='100%'
                variant={'unstyled'}
                border={'3px solid rgba(0,0,0,0.1)'}
                p='4.5px 16px'
                fontSize={'17px'}
                fontWeight={'semibold'}
                opacity='.8'
        />
            {
          i18n.language ==="ar" && (<InputLeftElement>
            <Button onClick={()=>{setShows(!shows)}}>{shows?<ViewOffIcon/>: <ViewIcon/>}</Button>
    
            </InputLeftElement>)
        }

{
          i18n.language ==="en" && (<InputRightElement>
            <Button onClick={()=>{setShows(!shows)}}>{shows?<ViewOffIcon/>: <ViewIcon/>}</Button>
        </InputRightElement>)
        }
        </InputGroup>
        </FormControl>
            <FormControl isRequired>
                <FormLabel color={'yellow.500'}>{t("confirmPassword")} </FormLabel>
                <Input 
                value={confirmNewPassword}
                onChange={onChangeConfirmPass}
                type={shows?'text' : "password"} 
              
                placeholder={t("enterPassword")}
                w='100%'
                variant={'unstyled'}
                border={'3px solid rgba(0,0,0,0.1)'}
                p='4.5px 16px'
                fontSize={'17px'}
                fontWeight={'semibold'}
                opacity='.8'
                />
            </FormControl>
            </VStack>
            <Button onClick={changePassword} colorScheme={'yellow'} w='100%'>{t("edit")} </Button>
        </VStack>
    </VStack>
  )
}

export default UserProfil