import { Flex, Menu, MenuButton, Button,Text, MenuList, MenuOptionGroup, MenuItemOption, MenuDivider, useDisclosure, Box, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody, VStack, Checkbox, Input} from '@chakra-ui/react'

import React from 'react'
import { HiOutlineFilter } from "react-icons/hi";
import {GrFormClose, GrSort} from 'react-icons/gr'
import SidebarSearchHook from '../../hook/search/sidebar-search-hook';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function useQuery(){
  return new URLSearchParams(useLocation().search)
}
const SearchResults = ({titleCount , onClick}) => {
  const { t, i18n } = useTranslation();
  let query = useQuery()
  let idCat = query.get("idCategory")
  let idBrand = query.get("idBrand")

    const [category, brand, clickCategory, clickBrand, priceFrom, priceTo] = SidebarSearchHook();
    let localFrom = localStorage.getItem("priceFrom")
    let localTo = localStorage.getItem("priceTo")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const clickMe = (key) => {
        localStorage.setItem("sortType", key)
        onClick()
    }
  return (
    <Flex w='100%' justifyContent={'space-between'} alignItems={'center'} >
        <Text fontSize={{sm:'25px' , base:'20px'}} fontWeight={'bold'}>{titleCount}</Text>
        <Flex gap="4px">
            <Box display={{base:"block" , md:"none"}}>
            <Button onClick={onOpen} color={"gray"}><HiOutlineFilter /></Button>
            <Drawer onClose={onClose} isOpen={isOpen}>
    
      <DrawerContent>
          <DrawerHeader borderBottomWidth='1px'>
            <Flex  alignItems={'center'} justifyContent='space-between'>
                {t("Filter")}
                <GrFormClose onClick={onClose}/>
            </Flex>
            </DrawerHeader>
          <DrawerBody>
          <VStack w='100%'>
        <Box w='100%'>
        <Text fontSize={'19px'}>{t("category")}</Text>
        <Flex flexDir={'column'} p='3'>
        {!idCat && <Checkbox onChange={clickCategory} value={"0"} size={'lg'} colorScheme={'yellow'}>All</Checkbox>}
        
        {
            category ? (category.map((item, index) => {
              return (
                  idCat?(
                      idCat === item._id && <Checkbox isChecked  size={'lg'} value={item._id} onChange={clickCategory} colorScheme={'yellow'} key={index}>{item.name}</Checkbox>
                  ):(
                    <Checkbox  size={'lg'} value={item._id} onChange={clickCategory} colorScheme={'yellow'} key={index}>{item.name}</Checkbox>

                  )

              )
            })) : <h6>    {t("noCategoryFound")} </h6>
          }
        
        </Flex>
        </Box>

        <Box w='100%'>
        <Text fontSize={'19px'}>{t("brand")}</Text>
        <Flex flexDir={'column'} p='3'>
        { !idBrand && <Checkbox value={'0'} onChange={clickBrand} size={'lg'} colorScheme={'yellow'}>All</Checkbox> }
        
        {
            brand ? (brand.map((item, index) => {
              return (
               
                  idBrand? (
                    idBrand === item._id &&<Checkbox isChecked value={item._id} key={index} onChange={clickBrand} size={'lg'} colorScheme={'yellow'}>{item.name}</Checkbox>
                  ):(
                    <Checkbox value={item._id} key={index} onChange={clickBrand} size={'lg'} colorScheme={'yellow'}>{item.name}</Checkbox>
                  )
             
              )
            })) : <h6>{t("noBrandFound")} </h6>
          }
        
        
        </Flex>
        </Box>

        <Box w='100%'>
        <Text  fontSize={'19px'}>{t("price")}</Text>
        <Box p='3'>
        <Flex alignItems={'center'} mb='7px'>
            <Text w={i18n.language === "ar" ? "30px" :"40px"} >{t("from")}</Text>
            <Input
            value={localFrom}
            onChange={priceFrom}
             type={'number'}
             w='70px' 
             h='30px'/>
        </Flex>
        <Flex alignItems={'center'}>
            <Text w={i18n.language === "ar" ? "30px" :"40px"}>{t("to")}</Text>
            <Input
            onChange={priceTo}
            value={localTo} 
            type='number' 
            w='70px' 
            h='30px'/>
        </Flex>
        </Box>
        </Box>
        
    </VStack>
            </DrawerBody>
        </DrawerContent>
      </Drawer>
            </Box>
        <Menu >
            <MenuButton as={Button} colorScheme='yellow'>
                <Flex alignItems={'center'}  gap="5px">
                <Text >{t("sort")}</Text>
                <GrSort/>
                </Flex>
            </MenuButton>
            <MenuList>
            
            <MenuItemOption onClick={() => clickMe("")}> {t("noOrder")} </MenuItemOption>

            <MenuDivider />

                    <MenuItemOption onClick={() => clickMe("الاكثر مبيعا")}> {t("mostseller")}</MenuItemOption>
                    <MenuItemOption onClick={() => clickMe("الاعلي تقييما")}> {t("mostrated")}</MenuItemOption>
                
                <MenuDivider />
              
                    <MenuItemOption onClick={() => clickMe("السعر من الاقل للاعلي")}>{t("lowerToHigh")} </MenuItemOption>
                    <MenuItemOption onClick={() => clickMe("السعر من الاعلي للاقل")}>{t("highToLower")}</MenuItemOption>
               
            </MenuList>
        </Menu>
        </Flex>
    </Flex>
  )
}

export default SearchResults