
import { useState, useEffect } from 'react'
import { getOneCategory } from '../../redux/actions/subcategoryAction';
import { createProduct } from '../../redux/actions/productsAction';
import { useSelector, useDispatch } from 'react-redux'
import { getAllCategory } from '../../redux/actions/cateogryAction'
import { getAllBrand } from './../../redux/actions/brandAction';
import { useToast } from '@chakra-ui/react';
import avatar from '../../images/avatar-removebg-preview.png'


const AdminAddProductsHook = () => {
    const [inputOptions, setInputOptions] = useState([])
  const [optionSize, setOptionSize] = useState([])
    const toast = useToast()

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllCategory());
        dispatch(getAllBrand());
    }, [])
    //get last catgeory state from redux
    const category = useSelector(state => state.allCategory.category)
    //get last brand state from redux
    const brand = useSelector(state => state.allBrand.brand)

    //get last sub cat state from redux
    const subCat = useSelector(state => state.subCategory.subcategory)

    const onSelect = (selectedList) => {
        setSeletedSubID(selectedList)
    }
    const onRemove = (selectedList) => {
        setSeletedSubID(selectedList)
    }
    const [imageCover, setImageCover] = useState(avatar)
    const [selectedFile, setSelectedFile] = useState(null)

    const onImageChange = (event) =>{
        if(event.target.files && event.target.files[0]){
          setImageCover( URL.createObjectURL(event.target.files[0]))
          setSelectedFile(event.target.files[0])
        }
      }
    const [options, setOptions] = useState([]);

    //values images products
    const [images, setImages] = useState({});
    //values state
    const [prodName, setProdName] = useState('');
    const [prodNameEn, setProdNameEn] = useState('');

    const [prodDescription, setProdDescription] = useState('');
    const [prodDescriptionEn, setProdDescriptionEn] = useState('');
    const [priceBefore, setPriceBefore] = useState('السعر قبل الخصم');
    const [priceAftr, setPriceAftr] = useState('السعر بعد الخصم');
    const [qty, setQty] = useState(0);
    const [CatID, setCatID] = useState('');
    const [BrandID, SetBrandID] = useState('');

    const [seletedSubID, setSeletedSubID] = useState([]);
    const [loading, setLoading] = useState(true);


    //to change name state
    const onChangeProdName = (event) => {
        event.persist();
        setProdName(event.target.value)
    }

    const onChangeProdNameEn = (event) => {
        event.persist();
        setProdNameEn(event.target.value)
    }
    //to change name state
    const onChangeDesName = (event) => {
       event.persist();
        setProdDescription(event.target.value)
    } 

    const onChangeDesNameEn = (event) => {
        event.persist();
         setProdDescriptionEn(event.target.value)
     } 
    //to change name state
    const onChangePriceBefor = (event) => {
        event.persist();
        setPriceBefore(event.target.value)
    }
    //to change name state
    const onChangePriceAfter = (event) => {
        event.persist();
        setPriceAftr(event.target.value)
    }  //to change name state
    const onChangeQty = (event) => {
        event.persist();
        setQty(event.target.value)
    }
    const onChangeColor = (event) => {
        event.persist();
        setShowColor(!showColor)
    }

    //to show hide color picker
    const [showColor, setShowColor] = useState(false);
    //to store all pick color
    const [colors, setColors] = useState([]);
    //when choose new color
    const handelChangeComplete = (color) => {
        setColors([...colors, color.hex])
        setShowColor(!showColor)
    }
    const removeColor = (color) => {
        const newColor = colors.filter((e) => e !== color)
        setColors(newColor)
    }



    //when selet category store id
    const onSeletCategory = async (e) => {
        if (e.target.value !== 0) {
            await dispatch(getOneCategory(e.target.value))
        }
        setCatID(e.target.value)
    }
    useEffect(() => {
        if (CatID !== 0) {
            if (subCat.data) {

                setOptions(subCat.data)
            }
        } else
            setOptions([])
    }, [CatID])

    //when selet brand store id
    const onSeletBrand = (e) => {
        SetBrandID(e.target.value)
    }

    //to convert base 64 to file
    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    const convertURLtoFile = async (url) => {
        const response = await fetch(url, { mode: "cors" });
        const data = await response.blob();
        const ext = url.split(".").pop();
        const filename = url.split("/").pop(); // Extract filename from URL
        const metadata = { type: `image/${ext}` };
        return new File([data], filename, metadata); // Use filename instead of Math.random()
    };

    //to save data 
    const handelSubmit = async (e) => {
        e.preventDefault();
        if (CatID === 0 || prodName === "" || prodDescription === "" || images.length <= 0 || priceBefore <= 0) {
            toast({
                title:'fill all inputs',
                status:'error',
                duration:3000,
                isClosable:true 
                })
            return;
        }

     

        const formData = new FormData();
       
        formData.append('ar[title]', prodName);
  formData.append('ar[description]', prodDescription);
  formData.append('en[title]',prodNameEn );
  formData.append('en[description]', prodDescriptionEn);
        formData.append("quantity", qty);
        formData.append("price", priceBefore);

        formData.append("category", CatID);
        formData.append("brand", BrandID);
        
        formData.append('imageCover', selectedFile); 
       
       
        colors.map((color) => formData.append("availableColors", color))
        optionSize.map((op) => formData.append("options", JSON.stringify(op)))
        seletedSubID.map((item) => formData.append("subcategory", item._id))
        

        setTimeout(async () => {
            setLoading(true)
            await dispatch(createProduct(formData))
            setLoading(false)
        }, 1000);

    }

    //get create meesage
    const product = useSelector(state => state.allproducts.products)

    useEffect(() => {

        if (loading === false) {
            // setCatID(0)
            setColors([])
            setImageCover(avatar)
            setProdName('')
            setProdNameEn('')
            setProdDescriptionEn('')
            setOptionSize([])
            setProdDescription('')
            setPriceBefore('السعر قبل الخصم')
            setPriceAftr('السعر بعد الخصم')
            setQty('الكمية المتاحة')
            SetBrandID(0)
            setSeletedSubID([])
            setTimeout(() => setLoading(true), 1500)

            if (product) {
                if (product.status === 201) {
                    toast({
                        title:'product added successfully',
                        status:'success',
                        duration:3000,
                        isClosable:true 
                        })
                        setTimeout(() => {
                            
                            window.location.reload()
                        }, 1000);
                } else {
                    toast({
                        title:'there is problem',
                        status:'error',
                        duration:3000,
                        isClosable:true 
                        })
                }
            }
        }
    }, [loading])


    return [onChangeDesName, onChangeQty, onChangeColor, onChangePriceAfter, onChangePriceBefor, onChangeProdName, showColor, category, brand, priceAftr, images, setImages, onSelect, onRemove, options, handelChangeComplete, removeColor, onSeletCategory, handelSubmit, onSeletBrand, colors, priceBefore, qty, prodDescription, prodName, prodNameEn , onChangeProdNameEn , prodDescriptionEn , onChangeDesNameEn , inputOptions , setInputOptions , optionSize , setOptionSize , imageCover , onImageChange]

}

export default AdminAddProductsHook