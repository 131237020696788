import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllUserCartItems } from "../../redux/actions/cartAction";

const GetAllUserCartHook = () => {
  const dispatch = useDispatch();
  const [cartID, setCartID] = useState("");
  const [loading, setLoading] = useState(true);
  const [itemsNum, setItemsNum] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [couponNameRes, setCouponName] = useState("");
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [totalCartPriceAfterDiscount, setTotalCartPriceAfterDiscount] =
    useState(0);
  const updateItems = useSelector((state) => state.cartReducer.updateItem);
  const resAddCoupon = useSelector((state) => state.cartReducer.applayCoupon);
  const user = localStorage.getItem("user");
  const fakeUser = localStorage.getItem("fakeUser");
  useEffect(() => {
    const get = async () => {
      setLoading(true);
      await dispatch(getAllUserCartItems({ fakeUser }));
      setLoading(false);
    };
    if (user != "" && user != null) {
      get();
    }
    if (fakeUser != "" && fakeUser != null) {
      get();
    }
  }, [updateItems, resAddCoupon]);
  const res = useSelector((state) => state.cartReducer.getAllUserCart);
  useEffect(() => {
    if (loading === false) {
      if (res && res.status === "success") {
        setCartID(res.data._id);
        setItemsNum(res.numOfCartItems);
        setCartItems(res.data.products);
        setTotalCartPrice(res.data.totalCartPrice);

        if (res.data.coupon) {
          setCouponName(res.data.coupon);
        } else {
          setCouponName("");
        }
        if (res.data.totalAfterDiscount) {
          setTotalCartPriceAfterDiscount(res.data.totalAfterDiscount);
        } else {
          setTotalCartPriceAfterDiscount("");
        }
      } else {
        setCouponName("");
        setTotalCartPriceAfterDiscount("");
        setItemsNum(0);
        setCartItems([]);
        setTotalCartPrice(0);
      }
    }
  }, [loading, resAddCoupon]);

  return [
    cartID,
    itemsNum,
    cartItems,
    totalCartPrice,
    couponNameRes,
    totalCartPriceAfterDiscount,
  ];
};

export default GetAllUserCartHook;
