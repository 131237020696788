import {
  Box,
  Button,
  FormControl,
  Heading,
  Image,
  Input,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import avatar from "../../images/avatar-removebg-preview.png";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getOneBrandAr,
  getOneBrandEn,
  updateBrand,
} from "../../redux/actions/brandAction";

const EditBrand = () => {
  const toast = useToast();
  const [img, setImg] = useState(avatar);
  const [name, setName] = useState("");
  const [nameEn, setNameEn] = useState("");
  const [loading, setLoading] = useState(true);

  const [selectedFile, setSelectedFile] = useState(null);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getOneBrandAr(id));
      await dispatch(getOneBrandEn(id));
    };
    fetchData();
  }, [dispatch, id]);
  const resAr = useSelector((state) => state.allBrand.getBrandAr);
  const resEn = useSelector((state) => state.allBrand.getBrandEn);
  const resUp = useSelector((state) => state.allBrand.updateBrand);

  useEffect(() => {
    if (resAr !== undefined) {
      setName(resAr?.data?.data?.name);
    }
  }, [resAr]);

  useEffect(() => {
    setNameEn(resEn?.data?.data?.name);
    setImg(resEn?.data?.data?.image);
  }, [resEn]);

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const onNameChangeEn = (e) => {
    setNameEn(e.target.value);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImg(URL.createObjectURL(event.target.files[0]));
      setSelectedFile(event.target.files[0]);
    }
  };

  const convertURLtoFile = async (url) => {
    const response = await fetch(url, { mode: "cors" });
    const data = await response.blob();
    const ext = url.split(".").pop();
    const filename = url.split("/").pop(); // Extract filename from URL
    const metadata = { type: `image/${ext}` };
    return new File([data], filename, metadata); // Use filename instead of Math.random()
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a new FormData instance
    const formData = new FormData();

    let imgCover;
    if (img.startsWith("http")) {
      imgCover = await convertURLtoFile(img);
      formData.append("image", imgCover);
    } else {
      formData.append("image", selectedFile);
    }
    // Append selected file to FormData

    // Create JSON objects for 'ar' and 'en' fields

    // Convert objects to JSON strings and append to FormData
    formData.append("ar[name]", name); // assuming 'name' contains Arabic name
    formData.append("en[name]", nameEn);
    if (img === avatar) {
      toast({
        title: "please add image ",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } else {
      await dispatch(updateBrand(id, formData));
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading === false) {
      if (resUp?.status === 200) {
        const fetchData = async () => {
          await dispatch(getOneBrandAr(id));
          await dispatch(getOneBrandEn(id));
        };
        fetchData();
        setLoading(true);
        toast({
          title: "Category Edit successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else if (resUp?.response?.status === 400) {
        toast({
          title: "  fill fields",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "error",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [resUp, loading, toast, selectedFile]);

  return (
    <Box>
      <Heading mb="20px">Edit Brand</Heading>
      <form>
        <FormControl isRequired>
          <label htmlFor="upload-photo">
            <Image
              mb="30px"
              src={img}
              cursor={"pointer"}
              w={{ md: "260px", base: "210" }}
              h={{ md: "200px", base: "150px" }}
            />
          </label>
          <Input
            w="0"
            variant={"unstyled"}
            type="file"
            name="photo"
            onChange={onImageChange}
            id="upload-photo"
          />
        </FormControl>
        <Tabs variant="soft-rounded" colorScheme="green">
          <TabList>
            <Tab>ِAR</Tab>
            <Tab>EN</Tab>
          </TabList>
          <TabPanels>
            <TabPanel px={"0px"}>
              <FormControl isRequired>
                <Input
                  onChange={onNameChange}
                  value={name}
                  placeholder="name"
                  w="100%"
                  variant={"unstyled"}
                  border={"3px solid rgba(0,0,0,0.1)"}
                  p="4.5px 16px"
                  fontSize={"17px"}
                  fontWeight={"semibold"}
                  opacity=".8"
                  mb="20px"
                />
              </FormControl>
            </TabPanel>
            <TabPanel px={"0px"}>
              <FormControl isRequired>
                <Input
                  onChange={onNameChangeEn}
                  value={nameEn}
                  placeholder="name english  "
                  w="100%"
                  variant={"unstyled"}
                  border={"3px solid rgba(0,0,0,0.1)"}
                  p="4.5px 16px"
                  fontSize={"17px"}
                  fontWeight={"semibold"}
                  opacity=".8"
                  mb="20px"
                />
              </FormControl>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Button onClick={handleSubmit} type="submit" colorScheme={"yellow"}>
          Edit
        </Button>
      </form>
    </Box>
  );
};

export default EditBrand;
