import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

function AdminSideBarSmallScreen({isOpen,onClose}) {
  return (
    <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color={'yellow.400'}>Settings</DrawerHeader>
          <DrawerBody>
          <VStack>
          <Link to='/admin/allorders' className='link' style={{width:'100%'}} >
            <Text  borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Order Management</Text>
        </Link>
        <Link to='/admin/allproducts' className='link' style={{width:'100%'}} >
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Products Management</Text>
        </Link>
        <Link to='/admin/addbrand' className='link' style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4'  fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Add Brand</Text>
        </Link>
        <Link to='/admin/addcategory' className='link'  style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Add Category</Text>
        </Link>
        
        <Link to='/admin/addproduct' className='link'  style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Add Product</Text>
        </Link>
        <Link to='/admin/addslider' className='link'  style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Add Slider</Text>
        </Link>
        <Link to='/admin/coupon' className='link'  style={{width:'100%'}}>
            <Text borderRadius={'5px'} p='4' fontSize={'17px'} fontWeight='500' _hover={{background:'yellow.500' }}>Add Coupon</Text>
        </Link>
      
      </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
  )
}

export default AdminSideBarSmallScreen