import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getAllProductPage, getAllProducts } from '../../redux/actions/productsAction';

const ViewHomeProductsHook = () => {
    const loading = useSelector((state) => state.allproducts.getAllProductPage)
    
    const dispatch = useDispatch();
    useEffect(() => {
        const get =async ()=>{

         await   dispatch(getAllProductPage())
        }
        get()
    },[])

    const allProducts = useSelector((state) => state.allproducts.getAllProductPage)
  
    let items = [];
   
        if (allProducts?.data)
            items = allProducts.data?.data.slice(0,10);
        else
            items = []
 

    return [items]

}

export default ViewHomeProductsHook