import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { addProductToWishList, removeProductToWishList } from './../../redux/actions/wishListAction';
import { useToast } from '@chakra-ui/react';
const ProductCardHook = (item, favProd) => {
    const toast = useToast()
    const dispatch = useDispatch();
    const [favImg, setFavImg] = useState(false)
    let Fav = favProd.some(fitem => fitem === item._id);
    const [loadingAdd, setLoadingAdd] = useState(true)
    const [loadingRemove, setLoadingRemove] = useState(true)
    const [isFav, setIsFav] = useState(Fav)
    const user = localStorage.getItem("user")


    useEffect(() => {
        setIsFav(favProd.some(fitem => fitem === item._id))
    }, [favProd])

    const handelFav = () => {
        if(user != "" && user !=null){
            if (isFav) {
                removeToWishListData();
            } else {
                addToWishListData()
            }
        }
        
    }

    useEffect(() => {

        if (isFav === true) {
            setFavImg(true)
        }
        else {
            setFavImg(false)
        }

    }, [isFav])

    const resAdd = useSelector(state => state.addToWishListReducer.addWishList)
    const resRemove = useSelector(state => state.addToWishListReducer.removeWishList)

    const addToWishListData = async () => {
        setIsFav(true)
        setFavImg(true)
        setLoadingAdd(true)
        await dispatch(addProductToWishList({
            productId: item._id,
        }))
        setLoadingAdd(false)
    }




    const removeToWishListData = async () => {
        setIsFav(false)
        setFavImg(false)
        setLoadingRemove(true)
        await dispatch(removeProductToWishList(item._id))
        setLoadingRemove(false)

    }


    useEffect(() => {
        if (loadingAdd === false) {
        
            if (resAdd && resAdd.status === 200) {
                toast({
                    title:'product added successfully to wishlist',
                    status:'success',
                    duration:3000,
                    isClosable:true 
                    })
            } else if (resAdd && resAdd.status === 401) {
                toast({
                    title:'you are not log in  ',
                    status:'error',
                    duration:3000,
                    isClosable:true 
                    })
            }
        }
    }, [loadingAdd])

    useEffect(() => {


        if (loadingRemove === false) {
    
            if (resRemove && resRemove.status === "success") {
                toast({
                    title:'product deleted successfully from wishlist',
                    status:'success',
                    duration:3000,
                    isClosable:true 
                    })
            } else if (resAdd && resAdd.status === 401) {
                toast({
                    title:'you are not log in  ',
                    status:'error',
                    duration:3000,
                    isClosable:true 
                    })
            }

        }
    }, [loadingRemove])


    return [removeToWishListData, addToWishListData, handelFav, favImg]
}

export default ProductCardHook