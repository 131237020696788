import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider,ColorModeScript} from '@chakra-ui/react'
import {BrowserRouter} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import store from './redux/store'
import { Provider } from 'react-redux';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ChakraProvider>
    <ColorModeScript initialColorMode='light'></ColorModeScript>
    <BrowserRouter >
    <App />
    </BrowserRouter>
    </ChakraProvider>
  </Provider>
);

